import React from 'react';
import {ThemeProvider} from '@mui/material';
import theme, {CustomTheme} from './constants/theme';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {AppSnackbarProvider} from './providers/snackbar';
import AppRouter from './AppRouter';
import {AlertDialogProvider} from './providers/AlertDialogProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <AppSnackbarProvider>
        <AlertDialogProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider<CustomTheme> theme={theme}>
              <AppRouter />
            </ThemeProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition={'bottom-left'}
            />
          </QueryClientProvider>
        </AlertDialogProvider>
      </AppSnackbarProvider>
    </div>
  );
}

export default App;
