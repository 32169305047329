import {useResize} from './useResize';
import {MutableRefObject} from 'react';

export interface DimensionProps {
  ref: MutableRefObject<null> | null;
  maxHeight: number;
  margin: Margin;
  scale: number;
}

export interface Margin {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export const useDimensions = ({
  ref = null,
  maxHeight = 0,
  margin = {left: 0, top: 0, right: 0, bottom: 0},
  scale = 0.5,
}: DimensionProps) => {
  const {width} = useResize(ref);

  const height =
    !maxHeight || width * scale < maxHeight ? width * scale : maxHeight;
  const innerWidth = width - (margin?.left || 0) - (margin?.right || 0);
  const innerHeight = height - (margin?.top || 0) - (margin?.bottom || 0);

  return {
    width,
    height,
    innerWidth,
    innerHeight,
  };
};
