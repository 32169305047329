import TextField from '@mui/material/TextField/TextField';
import React from 'react';
import {Grid, Stack} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button/Button';
import {useNavigate} from 'react-router-dom';
import auth from '../../lib/firebase/auth';
import Routes from '../../constants/Routes';
import {useForm} from 'react-hook-form';

export interface RegisterUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  privacyPolicy: boolean;
}

export const RegisterUserForm: React.FC = () => {
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      privacyPolicy: false,
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    const response = await auth.registerWithEmail(values);
    if (response.success) {
      navigate(Routes.home);
    }
  });

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...form.register('firstName')}
            required
            fullWidth
            variant="filled"
            id="firstName"
            label="First name"
            type="text"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...form.register('lastName')}
            required
            fullWidth
            variant="filled"
            id="lastName"
            label="Last name"
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...form.register('email')}
            required
            fullWidth
            variant="filled"
            id="email"
            label="Email"
            type="email"
            autoComplete="email"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...form.register('password')}
            required
            fullWidth
            variant="filled"
            id="password"
            label="Password"
            type="password"
            autoComplete="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            required
            name="policy"
            id="policy"
            value={form.watch('privacyPolicy')}
            onChange={(e) => form.setValue('privacyPolicy', e.target.checked)}
          />
          <span>
            {'* I accept the '}
            <a
              href="https://michaelguldborg.dk/privacy"
              target="_blank"
              rel="noopener noreferrer">
              privacy policy
            </a>
          </span>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={onSubmit}
            type="submit"
            aria-label="submit"
            variant="contained"
            color={'primary'}
            fullWidth
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              borderRadius: 40,
            }}>
            Register
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default RegisterUserForm;
