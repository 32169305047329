import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {BasePage} from '../../../components/BasePage';
import {Review, useReviews} from '../../../hooks/useReviews';

export const ReviewListPage = () => {
  const reviews = useReviews();

  return (
    <BasePage title={'Reviews'}>
      <DataGrid<Review>
        rows={reviews.query.data ?? []}
        filterMode={'client'}
        pagination={true}
        paginationMode={'client'}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        hideFooterSelectedRowCount={true}
        columns={[
          {
            field: 'rating',
            headerName: 'Rating',
          },
          {
            field: 'text',
            headerName: 'Text',
            flex: 1,
          },
          {
            field: 'version',
            headerName: 'Version',
          },
        ]}
      />
    </BasePage>
  );
};
