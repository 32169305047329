import {Currency} from '../lib/MonetaryAmount';

export type Account = {
  id: string;
  name: string;
  currency: Currency;
  index?: string;
  isin?: string;
  code?: string;
  exchange?: string;
  type?: 'account' | 'etf';
  category?: string;
  ocf?: number; // percentage cost per year
  aar?: number; // annual average return
  amount?: number; // current balance
  deposit?: number; // monthly investment
  cost?: number; // flat cost per year
};

export const accounts: Account[] = [
  {
    id: 'saxobank-dkk',
    type: 'account',
    name: 'Saxobank account DKK',
    category: 'cash',
    currency: 'DKK',
    aar: 0.0304,
  },
  {
    id: 'sydbank-dkk',
    type: 'account',
    name: 'Sydbank account DKK',
    category: 'cash',
    currency: 'DKK',
    aar: 0.005,
  },
  {
    id: '814957',
    name: 'Vanguard FTSE All-World UCITS ETF',
    amount: 0,
    deposit: 7000,
    currency: 'DKK',
    aar: 0.1018,
    ocf: 0.0022,
  },
  {
    id: '978294',
    name: 'Vanguard FTSE Developed World UCITS ETF',
    amount: 0,
    deposit: 7000,
    currency: 'DKK',
    aar: 0.1186,
    ocf: 0.001,
  },
  {
    id: '735528',
    name: 'Xtrackers MSCI World Health Care UCITS ETF 1C',
    amount: 0,
    deposit: 4500,
    currency: 'DKK',
    aar: 0.1443,
    ocf: 0.0025,
  },
  {
    id: '812436',
    name: 'IISL Nifty 50 NR USD',
    amount: 0,
    deposit: 4500,
    currency: 'DKK',
    aar: 0.1138,
    ocf: 0.0148,
  },
  {
    id: '962398',
    name: 'Vanguard FTSE Developed Europe UCITS ETF',
    category: 'cash',
    currency: 'DKK',
    type: 'account',
    aar: 0.098,
    ocf: 0.01,
    deposit: 4500,
    amount: 0,
  },
];

export const createAccount = (): Account => {
  return {
    id: `${Math.random() * 1000}`,
    name: '',
    category: 'cash',
    currency: 'DKK',
    type: 'account',
  };
};
