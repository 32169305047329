import React from 'react';
import {Box} from '@mui/material';

const FullCenterPage: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100wv"
      minHeight="100vh">
      {children}
    </Box>
  );
};

export default FullCenterPage;
