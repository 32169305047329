import {Challenge} from '../../../../data/challenges/challenges';
import {Grid} from '@mui/material';
import React from 'react';
import {ChallengeListItem} from './ChallengeListItem';
import {UserChallengeStatus} from '../../../../hooks/useUserChallenges';

export const ChallengeListView: React.FC<{
  items: Challenge[];
  onClick: (challenge: Challenge) => void;
  getStatus: (challenge: Challenge) => UserChallengeStatus | undefined;
  onChange: (e: React.MouseEvent, challenge: Challenge) => void;
}> = ({items, onClick, getStatus, onChange}) => {
  return (
    <Grid container spacing={3}>
      {items.map((challenge) => {
        return (
          <Grid key={challenge.id} item xs={12} sm={12} md={6} lg={3}>
            <ChallengeListItem
              challenge={challenge}
              status={getStatus(challenge)}
              onClick={() => onClick(challenge)}
              onChange={(e) => onChange(e, challenge)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
