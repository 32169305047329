import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {navigationList} from '../../constants/NavigationList';
import {useLocalRoute} from '../../hooks/useLocalAuthentication';
import {ChallengePage} from './challenges/ChallengePage/ChallengePage';
import ChecklistPage from '../_old/ChecklistPage';
import CategoryPage from './categories/CategoryPage/CategoryPage';
import {CategoryListPage} from './categories/CategoryListPage/CategoryListPage';
import {ChallengeListPage} from './challenges/ChallengeListPage/ChallengeListPage';
import {ReviewListPage} from './reviews/ReviewListPage';

export const MainRouter = () => {
  const location = useLocation();
  const [, setRoute] = useLocalRoute();
  setRoute(location.pathname);

  return (
    <Routes>
      <Route path={'/'} element={<CategoryListPage />} />
      <Route path={'/checklist/:checklistId'} element={<ChecklistPage />} />
      <Route path={'/challenges/:challengeId'} element={<ChallengePage />} />
      <Route path={'/categories/:categoryId'} element={<CategoryPage />} />
      <Route
        path={'/challenges/category/:categoryId'}
        element={<ChallengeListPage />}
      />

      {navigationList.map((e) => (
        <Route
          key={e.route}
          path={e.route.replace('/main', '')}
          element={<e.component />}
        />
      ))}

      <Route path={'/reviews'} element={<ReviewListPage />} />
      <Route element={<Navigate to={''} />} />
    </Routes>
  );
};

export default MainRouter;
