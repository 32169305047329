import React, {useState} from 'react';
import {
  DialogContent,
  Fab,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import snackbar from '../../../providers/snackbar';
import packageJson from '../../../../package.json';
import {useReviews} from '../../../hooks/useReviews';
import {useCurrentUser} from '../../../hooks/useCurrentUser';
import {BaseDialog} from '../../../components/BaseDialog/BaseDialog';
import StarLineIcon from 'remixicon-react/StarLineIcon';
import StarFillIcon from 'remixicon-react/StarFillIcon';

const version = packageJson['version'];

export const ReviewButton: React.FC = () => {
  const theme = useTheme();
  const currentUser = useCurrentUser();
  const reviews = useReviews();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [rating, setRating] = useState<number>();

  const onSubmit = async () => {
    if (rating === undefined) return;
    await reviews.onCreate({
      id: '',
      text: text,
      rating: rating + 1,
      version: version,
      userId: currentUser.id,
    });
    setText('');
    setRating(undefined);
    snackbar.success('Thank you for your feedback!');
    setOpen(false);
  };

  return (
    <>
      <Fab
        onClick={() => setOpen(true)}
        color={'primary'}
        sx={{
          position: 'fixed',
          bottom: {xs: 16 + 80, lg: 16},
          right: 16,
          zIndex: 100,
        }}>
        <StarFillIcon />
      </Fab>
      <BaseDialog
        header={'Share your experience'}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={'sm'}
        actions={[
          {
            title: 'Cancel',
            onClick: () => setOpen(false),
          },
          {
            title: 'Send review',
            color: 'primary',
            onClick: onSubmit,
          },
        ]}>
        <DialogContent sx={{minWidth: '400px'}}>
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'h6'}>{'How would you rate us?'}</Typography>
            <Stack p={1} direction={'row'} justifyContent={'space-between'}>
              {Array(5)
                .fill(0)
                .map((e, index) => {
                  const filled = rating !== undefined && rating >= index;
                  return (
                    <IconButton
                      key={`review-star-${index}`}
                      onClick={() => setRating(index)}
                      style={{
                        transition: 'transform 0.2s ease, color 0.3s ease',
                        color: filled
                          ? theme.palette.primary.main
                          : theme.palette.grey[300],
                        transform: filled ? 'scale(1.1)' : 'scale(1)',
                      }}
                      onMouseEnter={(e) => {
                        (e.currentTarget as HTMLElement).style.transform =
                          'scale(1.2)';
                      }}
                      onMouseLeave={(e) => {
                        (e.currentTarget as HTMLElement).style.transform =
                          filled ? 'scale(1.1)' : 'scale(1)';
                      }}>
                      {filled ? (
                        <StarFillIcon size={32} />
                      ) : (
                        <StarLineIcon size={32} />
                      )}
                    </IconButton>
                  );
                })}
            </Stack>
            <TextField
              placeholder={'Describe your experience...'}
              fullWidth
              multiline
              rows={4}
              InputProps={{sx: {p: 1}}}
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
          </Stack>
        </DialogContent>
      </BaseDialog>
    </>
  );
};
