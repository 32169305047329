import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Divider, Stack, useTheme} from '@mui/material';
import {DrawerHeader} from './DrawerHeader';
import {DrawerLogoutButton} from './DrawerLogoutButton';
import {DrawerButton} from './DrawerButton';
import {navigationList} from '../../../constants/NavigationList';
import drawerHandle from 'assets/images/drawer-handle.svg';

export const MainDrawer: React.FC<{
  open: boolean;
  setOpen: (b: boolean) => void;
}> = ({open, setOpen}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const onHide = () => setOpen(!open);

  return (
    <Box
      id={'main-drawer'}
      component={'nav'}
      sx={(theme) => ({
        display: {xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex'},
        backgroundColor: theme.palette.background.paper,
        width: open ? 280 : 130,
        minWidth: open ? 280 : 130,
        maxWidth: open ? 280 : 130,

        overflow: 'visible',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'column',
        transition:
          'width 300ms ease, min-width 300ms ease, max-width 300ms ease',
        zIndex: 100,
        boxShadow:
          theme.palette.mode === 'light'
            ? `-6px 0px 3px -3px rgb(0, 0, 0, 0.1) inset`
            : undefined,
      })}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          position: 'sticky',
          top: 0,
        }}>
        <DrawerHeader
          open={open}
          onClick={() => navigate('/main')}
          // onHide={() => setOpen(!open)}
        />
        <Stack direction={'column'} sx={{flex: 1, px: 2}}>
          {navigationList.map((e) => {
            const isActive = location.pathname === e.route;
            return (
              <DrawerButton
                route={e.route}
                id={e.name}
                key={e.route}
                name={e.name}
                open={open}
                isActive={isActive}
                icon={<e.icon />}
              />
            );
          })}
          <Divider sx={{marginBottom: 2}} />
          <DrawerLogoutButton open={open} />

          <Stack
            justifyContent={'center'}
            onClick={onHide}
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 16,
              cursor: 'pointer',
              transform: 'translate3d(4px, 0, 0)',
              transition: 'transform 100ms ease',
              backgroundColor: theme.palette.background.default,
              borderRadius: '20px 0px 0px 20px',
              boxShadow:
                theme.palette.mode === 'light'
                  ? '-2px 0 2px rgb(0,0,0, 0.15)'
                  : '-2px 0 2px rgb(0,0,0, 0.15)',
              px: 1,
              height: theme.spacing(7),
              '&:hover': {
                transform: open
                  ? 'translate3d(2px, 0, 0)'
                  : 'translate3d(6px, 0, 0)',
              },
            }}>
            <img src={drawerHandle} style={{width: '12px'}} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
