import React from 'react';
import {ChallengeCategoryId} from '../data/challenges/categories';
import {useCategories} from '../hooks/useCategories';
import {SvgIcon} from './SVGIcon';
import {Skeleton} from '@mui/material';

export const CategoryIcon: React.FC<{
  categoryId: ChallengeCategoryId | string | undefined;
}> = ({categoryId}) => {
  const categories = useCategories();

  if (!categories.query.isFetched) {
    return <Skeleton variant={'circular'} width={24} height={24} />;
  }

  const category = categories.query.data?.find((e) => e.id === categoryId);
  if (!category?.icon) {
    return <span>{categoryId}</span>;
  }

  return <SvgIcon value={category?.icon} />;
};
