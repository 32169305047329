import React from 'react';
import * as d3 from 'd3';
import {ChartController} from './MultiLineChart.controller';

interface ChartAxisProps {
  type: 'left' | 'bottom';
  controller: ChartController;
  ticks?: number;
  transform?: string;
  anchorEl?: Element | null;
}

export const ChartAxis: React.FC<ChartAxisProps> = ({
  type,
  controller,
  ticks,
  transform,
  ...props
}) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    const axisGenerator = type === 'left' ? d3.axisRight : d3.axisBottom;
    const scale = type === 'left' ? controller.yScale : controller.xScale;
    const tickFormat =
      type === 'left' ? controller.yTickFormat : controller.xTickFormat;
    // @ts-ignore
    const axis = axisGenerator(scale).ticks(ticks).tickFormat(tickFormat);
    const axisGroup = d3.select(ref.current);
    // @ts-ignore
    axisGroup.transition().duration(750).ease(d3.easeLinear).call(axis);
    axisGroup.select('.domain').remove();
    axisGroup.selectAll('line').remove();
    axisGroup.selectAll('text');
  }, [controller, ticks, type]);

  // React.useEffect(() => {
  //   d3.select(anchorEl)
  //     .on('mouseout.axisX', () => {
  //       d3.select(ref.current)
  //         .selectAll('text')
  //         .attr('opacity', 0.5)
  //         .style('font-weight', 'normal');
  //     })
  //     .on('mousemove.axisX', () => {
  //       const [x] = d3.mouse(anchorEl);
  //       const xDate = scale.invert(x);
  //       const textElements = d3.select(ref.current).selectAll('text');
  //       const data = textElements.data();
  //       const index = d3.bisector((d) => d).left(data, xDate);
  //       textElements
  //         .attr('opacity', (d, i) => (i === index - 1 ? 1 : 0.5))
  //         .style('font-weight', (d, i) =>
  //           i === index - 1 ? 'bold' : 'normal',
  //         );
  //     });
  // }, [anchorEl, scale]);

  return <g ref={ref} transform={transform} {...props} />;
};
