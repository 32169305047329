import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginPage from './pages/auth/LoginPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import RegisterUserPage from './pages/auth/RegisterUserPage';
import {MainPage} from './pages/main/MainPage';
import ErrorPage from './pages/error/ErrorPage';
import NotFoundPage from './pages/error/NotFoundPage';
import React from 'react';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<LoginPage />} />
        <Route path={'/reset-password'} element={<ResetPasswordPage />} />
        <Route path={'/register'} element={<RegisterUserPage />} />
        <Route path={'/main/*'} element={<MainPage />} />
        <Route path={'/error'} element={<ErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
