import React from 'react';
import * as d3 from 'd3';
// import {formatPercent, formatPriceUSD} from '../utils/commonUtils';
import {ChartController, DataSet} from './MultiLineChart.controller';
import {formatAmount} from '../lib/formatAmount';

export interface ChartTooltipProps {
  controller: ChartController;
  width: number;
  height: number;
  margin?: {top: number};
  data: DataSet[];
  anchorEl: unknown;
}

const color = '#aaa';
export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  controller,
  height,
  margin,
  data,
  anchorEl,
  ...props
}) => {
  const ref = React.useRef<SVGGElement>(null);

  const onMouseEvent = React.useCallback(
    (event: MouseEvent) => {
      const [x, y] = d3.pointer(event, anchorEl);
      const year = controller.xScale.invert(x);
      const value = controller.yScale.invert(y);
      const clampX = controller.xScale(Math.round(year));
      const clampY = controller.yScale(Math.round(value));
      const bisectDate = d3.bisector((d: any) => d.x).left;

      // draw circles online
      d3.select(ref.current)
        .selectAll('.tooltipLinePoint')
        .attr('fill', (cur, i) => data[i].color)
        .attr('transform', (cur, i) => {
          // find the closest point
          const index = bisectDate(data[i].items, year, 1);
          const d0 = data[i].items[index - 1];
          const d1 = data[i].items[index];
          const d = year - d0?.x > d1?.x - year ? d1 : d0;
          // move point out of container
          if (d.x === undefined && d.y === undefined) {
            return 'translate(-100,-100)';
          }

          // set point position
          const xPos = controller.xScale(d.x);
          const yPos = controller.yScale(d.y);

          // change tooltip text
          d3.selectAll('.value')
            .filter((td, tIndex) => tIndex === i)
            .text(`${formatAmount({value: d.y, currency: 'DKK'})}`);

          return `translate(${xPos}, ${yPos})`;
        });

      // draw line
      d3.select(ref.current)
        .select('.tooltipLine')
        .style('stroke', color)
        .attr('x1', clampX)
        .attr('x2', clampX)
        .attr('y1', -(margin?.top ?? 0))
        .attr('y2', height);

      // set title text
      d3.select(ref.current)
        .select('.title')
        .text(`Year: ${Math.round(year)}`);

      // draw title
      d3.select(ref.current)
        .select('.tooltip')
        .attr('transform', () => {
          return `translate(${clampX + 16}, ${clampY + 16})`;
        });

      // calculate new background size
      // const contentSize = (
      //   d3.select(ref.current).select('.tooltip').node() as SVGGElement
      // ).getBoundingClientRect();

      // set background size
      d3.select(ref.current)
        .select('.background')
        .attr('width', 140)
        .attr('height', 80);
    },
    [anchorEl, controller, data, height, margin?.top],
  );

  React.useEffect(() => {
    d3.select(anchorEl as string)
      .on('mouseout.tooltip', () => {
        d3.select(ref.current).attr('opacity', 0);
        d3.select(ref.current)
          .selectAll('.tooltipLinePoint')
          .attr('opacity', 0);
      })
      .on('mouseover.tooltip', () => {
        d3.select(ref.current).attr('opacity', 1);
        d3.select(ref.current)
          .selectAll('.tooltipLinePoint')
          .attr('opacity', 1);
      })
      .on('mousemove.tooltip', (event) => {
        onMouseEvent(event);
      });
  }, [anchorEl, onMouseEvent]);

  if (!data.length) return null;

  return (
    <g ref={ref} opacity={0} {...props}>
      <line className="tooltipLine" />
      <g className="tooltip">
        <rect className="background" rx={4} ry={4} opacity={0.2} />
        <text className="title" transform="translate(8,24)" />
        <g className="content" transform="translate(8,42)">
          {data.map(({name, color}, i) => (
            <g key={name} transform={`translate(8,${24 * i})`}>
              <circle r={6} fill={color} />
              <text className="value" transform="translate(10,4)" />
            </g>
          ))}
        </g>
      </g>
      {data.map(({name}) => (
        <circle className="tooltipLinePoint" r={6} key={name} opacity={0} />
      ))}
    </g>
  );
};
