import {useQuery} from '@tanstack/react-query';
import {useChallenges} from './useChallenges';
import {Challenge} from '../data/challenges/challenges';
import {Category} from '../data/challenges/categories';
import {useCategories} from './useCategories';
import {useCurrentUserChallenges} from './useCurrentUserChallenges';

export type UserCategory = Category & {
  value: number;
  valueMax: number;
  valuePercent: number;
};

export const useUserCategories = () => {
  const categories = useCategories();
  const challenges = useChallenges();
  const userChallenges = useCurrentUserChallenges();

  const query = useQuery({
    queryKey: ['user-categories'],
    enabled: categories.query.isFetched && challenges.query.isFetched,
    queryFn: () => {
      // group by category
      const groups =
        challenges.query.data?.reduce((r: Record<string, Challenge[]>, e) => {
          const c = e.category;
          if (!r[c]) r[c] = [e];
          else r[c] = [...r[c], e];
          return r;
        }, {}) ?? {};

      return Object.keys(groups)
        .map((key) => {
          const group = groups[key];
          const category = categories.query.data?.find((c) => c.id === key);
          const value = group.filter((c) => {
            const status = userChallenges.getStatus(c.id);
            return status === 'done';
          }).length;
          const valueMax = group.filter((c) => {
            const status = userChallenges.getStatus(c.id);
            return status !== 'declined';
          }).length;
          return {
            id: key,
            name: category?.name ?? key,
            icon: category?.icon,
            value,
            valueMax,
            valuePercent: value / valueMax,
          };
        })
        .toSorted((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  });

  return {
    query,
  };
};
