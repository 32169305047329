import {
  Box,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {BottomModal} from '../BottomModal/BottomModal';
import {StyledTextField} from '../StyledTextField/StyledTextField';
import {SelectFieldProps} from './SelectField';

export const SelectFieldMobile: React.FC<SelectFieldProps> = ({
  label,
  options,
  value,
  onChange,
  disabled,
  helperText,
  error,
}) => {
  const [open, setOpen] = useState(false);

  const valueToRender = options?.find((options) => options.id === value);

  const onClose = () => setOpen(false);

  return (
    <>
      <StyledTextField
        disabled={disabled}
        label={label}
        value={valueToRender?.label ?? ''}
        onClick={() => {
          if (disabled) return;
          setOpen(true);
        }}
        select
        helperText={helperText}
        error={error}
      />
      <BottomModal open={open} onClose={onClose}>
        <Box sx={{minHeight: '40vh'}}>
          <Typography
            textAlign={'center'}
            sx={{
              mt: 1,
              mb: 2,
            }}>
            {label}
          </Typography>
          {options?.map((option) => {
            return (
              <React.Fragment key={option.id}>
                <ListItemButton
                  disabled={option.disabled}
                  onClick={() => {
                    onChange?.(
                      // @ts-ignore
                      {target: {value: option.id, name: option.label}},
                      undefined,
                    );
                    onClose();
                  }}
                  sx={{
                    p: 2,
                  }}>
                  {option.icon && (
                    <ListItemAvatar>{option.icon}</ListItemAvatar>
                  )}
                  <ListItemText
                    primary={option.label}
                    primaryTypographyProps={{variant: 'body1'}}
                  />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            );
          })}
        </Box>
      </BottomModal>
    </>
  );
};
