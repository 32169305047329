import React from 'react';
import {Box, useTheme} from '@mui/material';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import CheckFillIcon from 'remixicon-react/CheckFillIcon';
import Tooltip from '@mui/material/Tooltip';
import {RemixiconReactIconProps} from 'remixicon-react';
import {UserChallengeStatus} from '../../../../hooks/useUserChallenges';

export interface StateIconProps {
  status?: UserChallengeStatus;
}

export const ChallengeStatusIcon: React.FC<StateIconProps> = ({status}) => {
  const theme = useTheme();
  if (status === 'done') {
    return (
      <BaseTooltip title={'Done'}>
        <BaseChallengeStateIcon
          background={theme.palette.success.light}
          color={theme.palette.success.contrastText}
          status={status}
        />
      </BaseTooltip>
    );
  }
  if (status === 'active') {
    return (
      <BaseTooltip title={'Active'}>
        <BaseChallengeStateIcon
          background={theme.palette.warning.light}
          color={theme.palette.warning.contrastText}
          status={status}
        />
      </BaseTooltip>
    );
  }
  if (status === 'declined') {
    return (
      <BaseTooltip title={'Declined'}>
        <BaseChallengeStateIcon
          background={theme.palette.error.light}
          color={theme.palette.error.contrastText}
          status={status}
        />
      </BaseTooltip>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: 1,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'grey',
        width: SIZE - 2 * 2,
        height: SIZE - 2 * 2,
      }}
    />
  );
};

const BaseTooltip: React.FC<{title: string; children: React.ReactNode}> = ({
  title,
  children,
}) => {
  return (
    <Tooltip title={title}>
      <div>{children}</div>
    </Tooltip>
  );
};

const SIZE = 24;
const BaseChallengeStateIcon: React.FC<{
  background: string;
  color: string;
  status?: UserChallengeStatus;
}> = ({status, background, color}) => {
  return (
    <Box
      sx={{background: background, borderRadius: 1, width: SIZE, height: SIZE}}>
      <BaseChallengeStateIconComponent status={status} color={color} />
    </Box>
  );
};

const BaseChallengeStateIconComponent: React.FC<
  {status?: UserChallengeStatus} & RemixiconReactIconProps
> = ({status, ...rest}) => {
  if (status === 'active') return <CheckFillIcon size={SIZE} {...rest} />;
  if (status === 'done') return <CheckFillIcon size={SIZE} {...rest} />;
  if (status === 'declined') return <CloseFillIcon size={SIZE} {...rest} />;
  return null;
};
