import {TextFieldProps} from '@mui/material/TextField/TextField';
import React from 'react';
import {TextField} from '@mui/material';
import {useTheme} from '@mui/material/styles';

export type OutlineType = 'single' | 'top' | 'middle' | 'bottom' | 'none';
export type StyledTextFieldProps = TextFieldProps & {
  outline?: OutlineType;
  onClear?: () => void;
  tooltip?: string;
  suffix?: string;
  select?: boolean;
  caption?: string;
  highlightBorder?: boolean;
};

export const StyledTextField = React.forwardRef<
  HTMLDivElement,
  StyledTextFieldProps
>(({outline = 'single', style, select, highlightBorder, ...rest}, ref) => {
  const theme = useTheme();

  const cursor = select ? {cursor: 'pointer'} : undefined;
  const border = {
    borderColor: rest.error
      ? theme.palette.error.main
      : highlightBorder
      ? theme.palette.primary.light
      : theme.palette.grey['400'],
  };

  return (
    <TextField
      ref={ref}
      fullWidth={true}
      style={{
        ...createBorderStyle(outline),
        ...style,
        ...cursor,
        ...border,
      }}
      {...rest}
    />
  );
});
StyledTextField.displayName = 'StyledTextField';

export const createBorderStyle = (outline: OutlineType) => {
  if (outline === 'none') return {borderWidth: 0};

  if (outline === 'single') {
    return {
      borderWidth: 1,
      borderStyle: 'solid',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    };
  }
  if (outline === 'top') {
    return {
      borderWidth: 1,
      borderStyle: 'solid',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    };
  }
  if (outline === 'middle') {
    return {
      borderWidth: 1,
      borderStyle: 'solid',
      borderTop: 'none',
    };
  }
  if (outline === 'bottom') {
    return {
      borderWidth: 1,
      borderStyle: 'solid',
      borderTop: 'none',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    };
  }
};
