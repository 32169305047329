import React from 'react';
import * as d3 from 'd3';
import {ChartController, DataSetItem} from './MultiLineChart.controller';

export interface ChartLineProps {
  controller: ChartController;
  data: DataSetItem[];
  color: string;
  isSmooth?: boolean;
  animation?: 'left' | 'fadeIn' | 'none';
}

export const ChartLine: React.FC<ChartLineProps> = ({
  controller,
  color = 'white',
  data = [],
  animation = 'left',
  ...props
}) => {
  const ref = React.useRef<SVGPathElement>(null);
  // Define different types of animation that we can use
  const animateLeft = React.useCallback(() => {
    // NOTE: for some reason getTotalLength() doesn't work in tests
    // in this codesandbox so we added default value just for tests
    const totalLength = ref.current?.getTotalLength?.() ?? 500;
    d3.select(ref.current)
      .attr('opacity', 1)
      .attr('stroke-dasharray', `${totalLength},${totalLength}`)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .duration(750)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
  }, []);
  const animateFadeIn = React.useCallback(() => {
    d3.select(ref.current)
      .transition()
      .duration(750)
      .ease(d3.easeLinear)
      .attr('opacity', 1);
  }, []);
  const noneAnimation = React.useCallback(() => {
    d3.select(ref.current).attr('opacity', 1);
  }, []);

  React.useEffect(() => {
    switch (animation) {
      case 'left':
        animateLeft();
        break;
      case 'fadeIn':
        animateFadeIn();
        break;
      case 'none':
      default:
        noneAnimation();
        break;
    }
  }, [animateLeft, animateFadeIn, noneAnimation, animation]);

  // Recalculate line length if scale has changed
  React.useEffect(() => {
    if (animation === 'left') {
      const totalLength = ref.current?.getTotalLength?.() ?? 500;
      d3.select(ref.current).attr(
        'stroke-dasharray',
        `${totalLength},${totalLength}`,
      );
    }
  }, [controller.xScale, controller.yScale, animation]);

  const line = d3
    .line<DataSetItem>()
    .x((d) => controller.xScale(d.x))
    .y((d) => controller.yScale(d.y));
  const d = line(data);

  return (
    <path
      ref={ref}
      // @ts-ignore
      d={d?.match(/NaN|undefined/) ? '' : d}
      stroke={color}
      strokeWidth={3}
      fill="none"
      opacity={0}
      {...props}
    />
  );
};
