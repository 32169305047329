import {useCrudListQuery} from '../lib/react-query/useCrudListQuery';
import {createFirestoreCrudService} from '../lib/firebase/createFirestoreCrudService';
import {Category} from '../data/challenges/categories';
import {CrudItem} from '../lib/firebase/CrudItem';

export const useCategories = () => {
  return useCrudListQuery<Category & CrudItem>(
    createFirestoreCrudService('categories', (v1, v2) => {
      return v1.name.localeCompare(v2.name);
    }),
  );
};
