import React from 'react';
import {DataSet, useController} from './MultiLineChart.controller';
import {ChartLine} from './ChartLine';
import {ChartAxis} from './ChartAxis';
import {ChartTooltip} from './ChartTooltip';
import {ChartOverlay} from './ChartOverlay';
import {useDimensions} from './useDimensions';

// const dimensions = {
//   width: 800,
//   height: 400,
//   innerWidth: 800,
//   innerHeight: 400,
// };

const margin = {
  top: 40,
  right: 30,
  bottom: 30,
  left: 30,
};

export const MultiLineChart: React.FC<{data: DataSet[]}> = ({data}) => {
  const overlayRef = React.useRef(null);
  const containerRef = React.useRef(null);
  const {width, height, innerWidth, innerHeight} = useDimensions({
    ref: containerRef,
    maxHeight: 0,
    margin,
    scale: 0.5,
  });

  const controller = useController({
    data,
    width: innerWidth,
    height: innerHeight,
  });

  return (
    <div ref={containerRef}>
      <svg width={width} height={height}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          {data.map(({name, items = [], color}) => (
            <ChartLine
              controller={controller}
              key={name}
              data={items}
              color={color}
            />
          ))}
          <ChartAxis type="left" controller={controller} ticks={10} />
          <ChartAxis type="bottom" controller={controller} ticks={10} />
          <ChartOverlay ref={overlayRef} width={width} height={height}>
            <ChartTooltip
              anchorEl={overlayRef.current}
              controller={controller}
              width={width}
              height={height}
              data={data}
            />
          </ChartOverlay>
        </g>
      </svg>
    </div>
  );
};
