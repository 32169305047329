import React from 'react';
import {RemixiconReactIconComponentType} from 'remixicon-react';
import HomeLineIcon from 'remixicon-react/HomeLineIcon';
import {ChallengeListPage} from '../pages/main/challenges/ChallengeListPage/ChallengeListPage';
import {CategoryListPage} from '../pages/main/categories/CategoryListPage/CategoryListPage';
import MoneyDollarBoxLineIcon from 'remixicon-react/MoneyDollarBoxLineIcon';
import {PortfolioPage} from '../pages/main/accounts/PortfolioPage';
import TaskLineIcon from 'remixicon-react/TaskLineIcon';

export interface NavItem {
  name: string;
  icon: RemixiconReactIconComponentType;
  route: string;
  component: React.ElementType;
}

export const navigationList: NavItem[] = [
  {
    name: 'Categories',
    icon: HomeLineIcon,
    route: '/main/categories',
    component: CategoryListPage,
  },
  {
    name: 'Challenges',
    icon: TaskLineIcon,
    route: '/main/challenges',
    component: ChallengeListPage,
  },
  {
    name: 'Investment',
    icon: MoneyDollarBoxLineIcon,
    route: '/main/money',
    component: PortfolioPage,
  },
];
