import React from 'react';
import packageJson from '../../../package.json';
import {Box} from '@mui/material';

export const VersionTag: React.FC = () => {
  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        bottom: 8,
        right: 16,
        color: theme.palette.info.dark,
      })}>
      <span>
        {packageJson['name']} v{packageJson['version']}
      </span>
    </Box>
  );
};

export default VersionTag;
