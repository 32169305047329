import {Account} from '../data/accounts';

export type ForecastItem = {
  id: string;
  year: number;
  principal: number;
  interest: number;
  deposit: number;
  cost: number;
  increase: number;
  value: number;
};
export const createAccountForecast = (
  account: Account | undefined,
  years: number = 30,
): ForecastItem[] => {
  if (!account) return [];
  const initial = {
    id: `${account.id}-${0}`,
    year: 0,
    principal: 0,
    interest: 0,
    deposit: 0,
    cost: 0,
    increase: 0,
    value: account.amount ?? 0,
  };
  const forecast = [initial];
  for (let year = 1; year <= years; year++) {
    const id = `${account.id}-${year}`;
    const principal = forecast[year - 1].value ?? 0;
    const interest = principal * (account.aar ?? 0);
    const deposit = (account.deposit ?? 0) * 12;
    const cost = principal * (account.ocf ?? 0);
    const increase = interest + cost + deposit;
    const value = principal + increase;
    forecast.push({
      id: id,
      year: year,
      principal,
      interest,
      deposit,
      cost,
      increase,
      value,
    });
  }

  return forecast.slice(1);
};
