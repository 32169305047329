/** MultilineChart.controller.js */
import {useMemo} from 'react';
import * as d3 from 'd3';

export interface ChartController {
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  xTickFormat: (v: number) => string;
  yTickFormat: (v: number) => string;
}

export type DataSet = {
  name: string;
  color: string;
  items: DataSetItem[];
};

export type DataSetItem = {
  x: number;
  y: number;
};

export interface ControllerProps {
  data: DataSet[];
  width: number;
  height: number;
}

export const useController = ({
  data,
  width,
  height,
}: ControllerProps): ChartController => {
  const xMin = useMemo(
    () => d3.min(data, ({items}) => d3.min(items, ({x}) => x)) as number,
    [data],
  );

  const xMax = useMemo(
    () => d3.max(data, ({items}) => d3.max(items, ({x}) => x)) as number,
    [data],
  );

  const xScale = useMemo(
    () => d3.scaleLinear().domain([xMin, xMax]).range([0, width]),
    [xMin, xMax, width],
  );

  const yMin = useMemo(
    () => d3.min(data, ({items}) => d3.min(items, ({y}) => y)) as number,
    [data],
  );

  const yMax = useMemo(
    () => d3.max(data, ({items}) => d3.max(items, ({y}) => y)) as number,
    [data],
  );

  const yScale = useMemo(() => {
    const indention = (yMax - yMin) * 0.5;
    return d3
      .scaleLinear()
      .domain([yMin - indention, yMax + indention])
      .range([height, 0]);
  }, [height, yMin, yMax]);

  const yTickFormat = (d: number) => {
    return `${d}`;
  };

  const xTickFormat = (d: number) => {
    return `${d} years`;
  };
  return {
    xTickFormat,
    yTickFormat,
    xScale,
    yScale,
  };
};
