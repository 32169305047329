import {Button, DialogActions, Divider, Stack, useTheme} from '@mui/material';
import React from 'react';

export interface BaseDialogAction {
  title: React.ReactNode;
  color?: 'primary' | 'secondary';
  onClick: () => void;
  disabled?: boolean;
}

export interface BaseDialogActionsProps {
  actions: BaseDialogAction[];
  isMobile?: boolean;
}

export const BaseDialogActions: React.FC<BaseDialogActionsProps> = ({
  actions,
  isMobile,
}) => {
  const theme = useTheme();
  if (isMobile) {
    return (
      <Stack sx={{mt: 'auto'}}>
        {actions.map((e, i) => {
          return (
            <React.Fragment key={`dialog-action-${i}`}>
              <Divider />
              <Button
                fullWidth
                sx={{
                  p: 2,
                  borderRadius: 0,
                  color:
                    e.color === 'primary'
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                }}
                variant={'text'}
                onClick={e.onClick}
                disabled={e.disabled}>
                {e.title}
              </Button>
            </React.Fragment>
          );
        })}
      </Stack>
    );
  }

  return (
    <Stack>
      <Divider />
      <DialogActions>
        {actions.map((e, i) => {
          return (
            <Button
              key={`dialog-action-${i}`}
              sx={{
                color:
                  e.color === 'primary'
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
              }}
              variant={'text'}
              onClick={e.onClick}
              disabled={e.disabled}>
              {e.title}
            </Button>
          );
        })}
      </DialogActions>
    </Stack>
  );
};
