import {useCrudListQuery} from '../lib/react-query/useCrudListQuery';
import {createFirestoreCrudService} from '../lib/firebase/createFirestoreCrudService';

export const statusList = ['none', 'active', 'done', 'declined'] as const;
export type UserChallengeStatus = typeof statusList[number];

export interface UserChallenge {
  id: string;
  userId: string;
  challengeId: string;
  status?: UserChallengeStatus;
  createdAt?: {seconds: number};
  updatedAt?: {seconds: number};
}

export const useUserChallenges = (userId: string | undefined) => {
  return useCrudListQuery<UserChallenge>(
    createFirestoreCrudService(`users/${userId}/challenges`, (v1, v2) => {
      const t1 = v1.updatedAt?.seconds ?? 0;
      const t2 = v2.updatedAt?.seconds ?? 0;
      return t2 - t1;
    }),
  );
};
