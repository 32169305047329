import React from 'react';
import {Box, Stack, Typography, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';

export const DrawerButton: React.FC<{
  id?: string;
  open: boolean;
  name: string;
  icon: React.ReactNode;
  badge?: number;
  onClick?: () => void;
  isActive?: boolean;
  route?: string;
}> = ({id, isActive, open, name, icon, badge, onClick, route}) => {
  const theme = useTheme();
  return (
    <Stack
      id={id}
      component={route ? Link : 'div'}
      to={route ?? ''}
      direction={'row'}
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 1,
        marginBottom: 2,
        cursor: 'pointer',
        background: isActive ? theme.palette.primary.main : undefined,
        borderRadius: 1,
      }}>
      <span
        style={{
          width: 25,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: isActive ? theme.palette.background.paper : undefined,
        }}>
        {icon}
      </span>
      <Typography
        variant={'body1'}
        noWrap
        sx={{
          marginLeft: 2,
          flex: 1,
          opacity: open ? 1 : 0,
          transition: 'opacity 100ms ease',
          fontWeight: isActive ? 700 : 400,
          color: isActive ? theme.palette.background.paper : undefined,
        }}>
        {name}
      </Typography>
      {Boolean(badge) && (
        <Box
          sx={{
            width: 'fit-content',
            px: 1,
            py: 0.5,
            borderRadius: 1,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.background.paper,
            ...(isActive && {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.primary.main,
            }),
          }}>
          <Typography typography={'button'} noWrap>
            {badge}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
