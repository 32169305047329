import {useUserChallenges} from './useUserChallenges';
import {useAuthState} from 'react-firebase-hooks/auth';
import {firebaseAuth} from '../lib/firebase/firebase';

export const useCurrentUserChallenges = () => {
  const [user] = useAuthState(firebaseAuth);
  const challenges = useUserChallenges(user?.uid);

  const getStatus = (challengeId: string) => {
    const data = challenges.query.data;
    return data?.find((e) => e.challengeId === challengeId)?.status;
  };

  return {
    ...challenges,
    getStatus,
  };
};
