import {useCrudListQuery} from '../lib/react-query/useCrudListQuery';
import {Challenge, difficulties} from '../data/challenges/challenges';
import {createFirestoreCrudService} from '../lib/firebase/createFirestoreCrudService';
import {CrudItem} from '../lib/firebase/CrudItem';

export const useChallenges = () => {
  return useCrudListQuery<Challenge & CrudItem>(
    createFirestoreCrudService('challenges', (v1, v2) => {
      // difficulty
      const d1 = difficulties.indexOf(v1.difficulty);
      const d2 = difficulties.indexOf(v2.difficulty);
      const difficultyDiff = d1 - d2;
      if (difficultyDiff) return difficultyDiff;

      // category
      const categoryDiff = v2.category?.localeCompare(v1.category ?? '');
      if (categoryDiff) return categoryDiff;

      // name
      return v1.name.localeCompare(v2.name);
    }),
  );
};
