import createLocalCrudService from '../../../../lib/firebase/createLocalCrudService';
import {useCrudListQuery} from '../../../../lib/react-query/useCrudListQuery';
import {useQueryState} from '../../../../lib/react-query/useQueryState';
import {MonetaryAmount} from '../lib/MonetaryAmount';

export type CountryCode = 'DK';
export type Portfolio = {
  id: string;
  name: string;
  country: CountryCode;
  expenses: MonetaryAmount;
  inflation: number;
  years: number;
};

const initial: Portfolio[] = [
  {
    id: 'private',
    name: 'Private',
    country: 'DK',
    expenses: {value: 20000, currency: 'DKK'},
    inflation: 0.02,
    years: 30,
  },
  {
    id: 'company',
    name: 'Company',
    country: 'DK',
    expenses: {value: 20000, currency: 'DKK'},
    inflation: 0.02,
    years: 30,
  },
];

export const usePortfolios = () => {
  const service = createLocalCrudService('portfolios', initial);
  const crud = useCrudListQuery(service);
  const [id, setId] = useQueryState(['portfolio'], 'private');
  const current = crud.query.data?.find((e) => e.id === id);

  return {
    id,
    setId,
    current,
    ...crud,
  };
};
