import React from 'react';
import {MultiLineChart} from './MultiLineChart';
import {createAccountForecast} from '../ForecastTable/createAccountForecast';
import {useAccounts} from '../hooks/useAccounts';
import {createPortfolioForecast} from '../ForecastTable/createPortfolioForecast';
import {usePortfolios} from '../hooks/usePortfolios';
import Paper from '@mui/material/Paper';
import {ListItemText, Stack, useTheme} from '@mui/material';
import {formatAmount} from '../lib/formatAmount';

export const ForecastChart: React.FC = () => {
  const theme = useTheme();
  const portfolio = usePortfolios();
  const inflation = portfolio.current?.inflation ?? 0;
  const years = portfolio.current?.years ?? 30;
  const accounts = useAccounts(portfolio.id);

  const budget = createAccountForecast(
    createBudgetAccount({
      value: portfolio.current?.expenses.value ?? 0,
      rate: inflation ?? 0,
    }),
    years,
  );
  const forecast = createPortfolioForecast(accounts.query.data, years);

  const target = forecast.find((e, index) => e.interest >= budget[index].value);
  return (
    <Paper sx={{p: 2}}>
      <Stack direction={'row'} spacing={2}>
        <TextBox title={'Target year'} subtitle={target?.year} />
        <TextBox
          title={'Monthly investment'}
          subtitle={`${formatAmount({
            value: (target?.deposit ?? 0) / 12,
            currency: 'DKK',
          })} /month`}
        />
        <TextBox
          title={'Target interest'}
          subtitle={`${formatAmount({
            value: (target?.interest ?? 0) / 12,
            currency: 'DKK',
          })} /month`}
        />
        <TextBox
          title={'Target interest'}
          subtitle={`${formatAmount({
            value: target?.interest ?? 0,
            currency: 'DKK',
          })} /year`}
        />
        <TextBox
          title={'Target amount'}
          subtitle={formatAmount({
            value: target?.value,
            currency: 'DKK',
          })}
        />
      </Stack>
      <MultiLineChart
        data={[
          {
            name: 'Budget',
            color: theme.palette.error.light,
            items: budget.map((e) => ({x: e.year, y: e.value})),
          },
          {
            name: 'Interest',
            color: theme.palette.success.light,
            items: forecast.map((e) => ({x: e.year, y: e.interest})),
          },
        ]}
      />
    </Paper>
  );
};

export const TextBox: React.FC<{
  title: string;
  subtitle: string | number | undefined;
}> = ({title, subtitle}) => {
  return (
    <Paper sx={{px: 2, py: 1, minWidth: 80}}>
      <ListItemText primary={title} secondary={subtitle} />
    </Paper>
  );
};

const createBudgetAccount = (options: {value: number; rate: number}) => {
  return {
    id: 'budget',
    name: 'Budget',
    currency: 'DKK',
    type: 'account',
    category: 'cash',
    amount: options.value * 12,
    aar: options.rate,
  } as const;
};
