import React, {useState} from 'react';
import MainRouter from './MainRouter';
import {Box, useTheme} from '@mui/material';
import {MainDrawer} from './navigation/MainDrawer';
import {MainBottomNavBar} from './navigation/MainBottomNavBar';
import {ReviewButton} from './reviews/ReviewButton';

export const MainPage = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  return (
    <Box
      id={'main-page-root'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.grey['50'],
      }}>
      <ReviewButton />
      <MainDrawer open={open} setOpen={setOpen} />
      <Box
        id={'main-page'}
        sx={{display: 'flex', flex: 1, flexDirection: 'column'}}>
        <Box
          id={'main-content'}
          sx={{
            background: theme.palette.grey['50'],
            flex: 1,
            position: 'relative',
            overflowY: 'hidden',
            height: '100vh',
            backgroundColor: theme.palette.background.default,
            transition:
              'width 300ms ease, min-width 300ms ease, max-width 300ms ease',
            maxWidth: 'calc(100vw - 4px)',
            [theme.breakpoints.up('lg')]: {
              maxWidth: `calc(100vw - ${open ? 280 : 130}px - 4px)`,
            },
            [theme.breakpoints.down('lg')]: {
              pb: 12,
            },
          }}>
          <MainRouter />
        </Box>
        <MainBottomNavBar />
      </Box>
    </Box>
  );
};

export default MainPage;
