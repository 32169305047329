import React, {useState} from 'react';
import TextField from '@mui/material/TextField/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {Stack} from '@mui/material';

export interface ResetPasswordFormValues {
  password: string;
  passwordRepeat: string;
  token: string;
}

const ResetPasswordForm: React.FC = () => {
  // const paramsSearch = useTypedSelector(state => state.router?.location.search);
  // const params = qs.parse(paramsSearch ?? '', {ignoreQueryPrefix: true});
  // const token = "" + params['token'];

  const [sentRequest] = useState<boolean>(false);

  // const onSubmit = async () => {
  //   const response = await services.auth.resetPassword(values.password, values.token);
  //   if (response.success) {
  //       setSendtRequest(true);
  //   }
  // };
  //
  // const initialValues = {
  //   password: '',
  //   passwordRepeat: '',
  //   token: '',
  // };

  return (
    <Stack>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        required
        id="password"
        name="password"
        label="Adgangskode"
        type={'password'}
        autoComplete="current-password"
      />

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        required
        id="passwordRepeat"
        name="passwordRepeat"
        label="Gentag adgangskode"
        type={'password'}
        autoComplete="current-password-repeat"
      />
      <Button
        type="submit"
        aria-label="submit"
        variant="contained"
        color={'primary'}
        fullWidth
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          borderRadius: 40,
        }}>
        Reset password
      </Button>
      {sentRequest && (
        <Alert severity={'success'}>
          Din adgangskode er succesfuldt blevet ændret
        </Alert>
      )}
    </Stack>
  );
};

export default ResetPasswordForm;
