import React from 'react';
import {LoginForm} from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import LogoPage from '../../components/containers/LogoPage';
import {useLocation} from 'react-router-dom';

const LoginPage: React.FC = () => {
  const location = useLocation();

  const anchor = location.hash;
  const showForgotPassword = anchor === '#forgot-password';

  return (
    <LogoPage>
      {showForgotPassword ? <ForgotPasswordForm /> : <LoginForm />}
    </LogoPage>
  );
};

export default LoginPage;
