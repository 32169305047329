import {db, firebaseAuth} from '../lib/firebase/firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import {useDocument} from 'react-firebase-hooks/firestore';
import {doc} from 'firebase/firestore';
import UserData from '../models/UserData';

export const useCurrentUser: () => UserData = () => {
  const [user] = useAuthState(firebaseAuth);
  const [snapshot] = useDocument(doc(db, 'users', user?.uid ?? 'undefined'));

  return {
    ...snapshot?.data(),
    id: user?.uid,
  } as UserData;
};
