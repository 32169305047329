import React from 'react';
import {Box, Grid} from '@mui/material';
import Paper from '@mui/material/Paper';
import VersionTag from '../../components/displays/VersionTag';

const LogoPage: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <Grid container sx={{height: '100vh'}}>
      <Grid item xs={12} sm={8} md={5} lg={4}>
        <Paper
          elevation={12}
          square
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{position: 'relative'}}>
            <Box padding={6}>{children}</Box>
            <VersionTag />
          </Box>
        </Paper>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        sx={{
          background: 'linear-gradient(to top left, #3a1c71, #d76d77, #ffaf7b)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              width: '20%',
              height: '20%',
              backgroundRepeat: 'no-repeat',
              // backgroundImage: `url("${LogoIcon}")`,
              backgroundSize: 'contain',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LogoPage;
