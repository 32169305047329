import {
  Box,
  Dialog,
  DialogProps,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import {BaseDialogActions, BaseDialogActionsProps} from './BaseDialogActions';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

export interface BaseDialogProps extends DialogProps {
  header?: string;
  open: boolean;
  onClose?: () => void;
  image?: string;
  actions?: BaseDialogActionsProps['actions'];
}

export const BaseDialog: React.FC<BaseDialogProps> = ({
  header,
  open,
  onClose,
  children,
  maxWidth,
  image,
  actions,
  ...rest
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth={maxWidth ?? 'md'}
      fullWidth
      open={open}
      onClose={onClose}
      {...rest}
      PaperProps={{
        sx: {
          zIndex: 1,
          border: 'none',
          backgroundImage: 'none',
          backgroundColor: theme.palette.background.default,
          boxShadow:
            '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
        },
      }}>
      <Stack>
        <Stack px={3} py={2} direction={'row'} alignItems={'center'}>
          <Typography flex={1} typography={'h6'}>
            {header}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseLineIcon />
          </IconButton>
        </Stack>
        <Divider />
      </Stack>
      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          '::-webkit-scrollbar': {display: 'none'},
        }}>
        {image && (
          <img
            src={image}
            alt={'cover image'}
            style={{
              objectFit: 'cover',
              objectPosition: 'bottom center',
              width: '100%',
            }}
          />
        )}
        {children}
      </Box>
      {actions && <BaseDialogActions actions={actions} isMobile={isMobile} />}
    </Dialog>
  );
};
