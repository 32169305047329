import React, {useMemo} from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import {SelectInputProps} from '@mui/material/Select/SelectInput';
import {SelectProps} from '@mui/material/Select/Select';
import {SelectFieldOption} from './SelectFieldOption';
import {SelectFieldMobile} from './SelectFieldMobile';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import IconButton from '@mui/material/IconButton';

export type SelectFieldProps = SelectProps & {
  label?: string;
  options: SelectFieldOption[] | undefined;
  value?: string;
  onChange?: SelectInputProps['onChange'];
  onClear?: () => void;
  isMobile?: boolean;
  helperText?: React.ReactNode;
};

export const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
  (
    {
      fullWidth = true,
      label,
      options,
      disabled,
      error,
      value,
      isMobile,
      onChange,
      onClear,
      helperText,
      ...rest
    },
    ref,
  ) => {
    const valueMap = useMemo(() => {
      return toMap(options ?? [], 'id');
    }, [options]);

    const renderValue = (selected: unknown): React.ReactNode => {
      if (Array.isArray(selected)) {
        return selected
          .map((e) => {
            if (typeof e === 'string') return valueMap[e]?.label;
            return e;
          })
          .join(', ');
      }

      if (typeof selected === 'string') {
        return valueMap[selected]?.label;
      }

      return '';
    };

    const labelId = 'select-' + label?.toLowerCase() + '-label';

    if (isMobile)
      return (
        <SelectFieldMobile
          label={label}
          options={options}
          value={value}
          onChange={onChange}
          disabled={disabled}
          helperText={helperText}
          error={error}
        />
      );

    return (
      <FormControl
        error={error}
        fullWidth={fullWidth}
        sx={[
          {
            '& .MuiInputLabel-shrink': {marginTop: 2},
            '& .MuiSvgIcon-root': {marginTop: 0.1, marginRight: 1},
            '& .MuiSelect-select': {px: 2, pt: 2.5, pb: 1.5},
          },
          !label && {
            '& .MuiSelect-select': {p: 2},
          },
          !!helperText && {'& .MuiSelect-select': {mb: 1}},
        ]}>
        <InputLabel id={labelId} sx={{marginLeft: 0}}>
          {label}
        </InputLabel>
        <Select
          ref={ref}
          labelId={labelId}
          renderValue={renderValue}
          style={{marginTop: 0}}
          disabled={disabled || !options?.length}
          fullWidth={fullWidth}
          onChange={onChange}
          sx={(theme) => ({borderColor: theme.palette.grey[400]})}
          value={value}
          endAdornment={
            onClear && (
              <IconButton
                size={'small'}
                onClick={onClear}
                sx={{display: !value ? 'none' : 'flex', mr: 3}}>
                <CloseFillIcon />
              </IconButton>
            )
          }
          {...rest}>
          {options?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              disabled={option.disabled}>
              {option.icon && <ListItemAvatar>{option.icon}</ListItemAvatar>}
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText sx={{mb: 0.5, mt: -2.5}}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  },
);

SelectField.displayName = 'SelectField';

export const toMap = <TElement,>(list: TElement[], key: keyof TElement) => {
  const initial: {[k: string]: TElement} = {};
  return list.reduce((result, value) => {
    const id = `${value[key]}`;
    result[id] = value;
    return result;
  }, initial);
};
