import React, {MouseEventHandler} from 'react';
import {Avatar, Stack, Typography, useTheme} from '@mui/material';

export interface DrawerHeaderProps {
  open: boolean;
  onClick?: MouseEventHandler;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({open, onClick}) => {
  const theme = useTheme();
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{mb: 2, position: 'relative', height: theme.spacing(9)}}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        pl={3}
        sx={{cursor: 'pointer'}}
        onClick={onClick}>
        {open ? (
          <Typography variant={'h5'} style={{fontWeight: '700'}}>
            {'Achievement'}
          </Typography>
        ) : (
          <Avatar sx={{background: theme.palette.primary.main}}>
            <Typography variant={'h5'} style={{fontWeight: '700'}}>
              {'A'}
            </Typography>
          </Avatar>
        )}
      </Stack>
    </Stack>
  );
};

export {DrawerHeader};
