import {useSearchParams} from 'react-router-dom';

export const useSearchParamForm = <T extends Record<string, string>>(
  initial: T,
) => {
  const [params, setParams] = useSearchParams();
  const setValue = (key: keyof T, value: string) => {
    setParams((params) => {
      params.set(String(key), value);
      return params;
    });
  };

  const keys = Object.keys(initial) as (keyof T)[];
  const values = keys.reduce((state: Record<string, string>, key: keyof T) => {
    const _key = String(key);
    const value = params.get(_key);
    if (typeof value !== 'string') return state;
    state[_key] = value;
    return state;
  }, initial) as T;
  return {params, setParams, values, setValue};
};
