import {useCallback} from 'react';
import {useQueryState} from './useQueryState';

export const useGlobalState = <T extends object>(key: string[], initial: T) => {
  const [state, setState] = useQueryState<T>(key, initial);
  const reset = () => setState(() => initial);

  const update = useCallback(
    (update: Partial<T>) => {
      return setState((prev) => {
        const state = prev ?? initial;
        return {...state, ...update};
      });
    },
    [initial, setState],
  );

  return {
    state,
    setState,
    update,
    reset,
  };
};

export default useGlobalState;
