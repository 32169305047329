import React from 'react';
import {Box, Divider, Typography} from '@mui/material';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage: React.FC = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={(theme) => ({
        width: '100%',
        padding: theme.spacing(8, 4),
        paddingTop: theme.spacing(4),
        alignItems: 'center',
      })}>
      <Typography component="h2" variant="h5">
        {'Nulstil adgangskode'}
      </Typography>

      <Typography variant="subtitle1">
        {'Indtast din nye adgangskode'}
      </Typography>

      <Box mt={3} mb={3}>
        <Divider orientation="horizontal" variant="fullWidth" />
      </Box>
      <ResetPasswordForm />
    </Box>
  );
};

export default ResetPasswordPage;
