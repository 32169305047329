import {MonetaryAmount} from './MonetaryAmount';
import {LocaleType} from './LocaleType';

export const formatAmount = (
  amount: MonetaryAmount | undefined,
  locale: LocaleType = 'da-DK',
) => {
  if (amount?.value === undefined || amount?.currency === undefined) {
    return '-';
  }
  const number = Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount.value);
  const symbol = getCurrencySymbol(locale, amount.currency);
  return `${number} ${symbol}`;
};

export const getCurrencySymbol = (locale: string, currency: string) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, '')
    .trim();
};
