export class Routes {
  // auth
  static readonly landing = '/';
  static readonly forgotPassword = '#forgot-password';
  static readonly resetPassword = '/reset-password';
  static readonly register = '/register';
  static readonly registerAuth = '/register/auth';
  static readonly registerPicture = '/register/picture';

  // error
  static readonly error = '/error';
  static readonly test = '/test';

  // dashboard
  static readonly home = '/main';
  static readonly challenges = '/main/challenges';
  static readonly challenge = '/main/challenges/:challengeId';
  static readonly checklist = '/main/checklist/:checklistId';
  static readonly challenges30Day = '/main/challenges/:challengeId/30-day';
  static readonly homeLanding = '/main/landing';
  static readonly lifeMap = '/main/life-map';
  static readonly habits = '/main/habits';
  static readonly agenda = '/main/agenda';
  static readonly activity = '/main/activity/:activityId';

  static readonly profile = '/main/profile';
}

export default Routes;
