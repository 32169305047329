import {Updater, useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';

export const useQueryState = <T>(
  key: string[],
  initial: T,
): [T, (updater: Updater<T | undefined, T>) => T | undefined] => {
  const client = useQueryClient();
  const query = useQuery({
    queryKey: key,
    initialData: initial,
    queryFn: () => client.getQueryData<T>(key),
    enabled: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
  const setState = useCallback(
    (updater: Updater<T | undefined, T>) => {
      return client.setQueryData<T>(key, updater);
    },
    [client, key],
  );

  return [query.data ?? initial, setState];
};
