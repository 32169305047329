import {DataGrid} from '@mui/x-data-grid';
import {Button, IconButton, Stack} from '@mui/material';
import React from 'react';
import {useAccounts} from '../hooks/useAccounts';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import {Account, createAccount} from '../data/accounts';
import {useRenderAccountCell} from './useRenderAccountCell';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpLineIcon from 'remixicon-react/ArrowUpLineIcon';
import ArrowDownLineIcon from 'remixicon-react/ArrowDownLineIcon';

export const AccountTable: React.FC<{id: string | undefined}> = ({id}) => {
  const accounts = useAccounts(id);
  const renderCell = useRenderAccountCell(id);

  return (
    <Stack>
      <DataGrid<Account>
        rows={accounts.elements}
        hideFooter={true}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            ...renderCell('name', 'text'),
          },
          {
            field: 'currency',
            headerName: 'Currency',
            ...renderCell('currency', 'text'),
          },
          {
            field: 'amount',
            headerName: 'Amount',
            ...renderCell('amount', 'amount'),
          },
          {
            field: 'deposit',
            headerName: 'Monthly deposit',
            ...renderCell('deposit', 'amount'),
          },
          {
            field: 'aar',
            headerName: 'AAR',
            ...renderCell('aar', 'percent'),
          },
          {
            field: 'ocf',
            headerName: 'OCF',
            ...renderCell('ocf', 'percent'),
          },
          {
            field: '',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 140,
            renderCell: (e) => {
              return (
                <Stack direction={'row'}>
                  <Tooltip title={'Move up'}>
                    <IconButton
                      onClick={() => {
                        const rowId = e.row.id;
                        const index = accounts.elements.findIndex(
                          (a) => a.id === rowId,
                        );
                        const isFirst = index === 0;
                        if (index === -1 || isFirst) return;
                        const prev = accounts.elements[index - 1];
                        accounts.onUpdate({id: rowId, index: `${index - 1}`});
                        accounts.onUpdate({id: prev.id, index: `${index}`});
                        setTimeout(() => accounts.query.refetch(), 200);
                      }}>
                      <ArrowUpLineIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Move down'}>
                    <IconButton
                      onClick={() => {
                        const rowId = e.row.id;
                        const index = accounts.elements.findIndex(
                          (a) => a.id === rowId,
                        );
                        const isLast = index === accounts.elements.length - 1;
                        if (index === -1 || isLast) return;
                        const prev = accounts.elements[index + 1];
                        accounts.onUpdate({id: rowId, index: `${index + 1}`});
                        accounts.onUpdate({id: prev.id, index: `${index}`});
                        setTimeout(() => accounts.query.refetch(), 200);
                      }}>
                      <ArrowDownLineIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Delete'}>
                    <IconButton onClick={() => accounts.onDelete(e.row.id)}>
                      <CloseLineIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              );
            },
          },
        ]}
      />
      <Button
        variant={'text'}
        sx={(theme) => ({
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.grey[300],
          borderTopWidth: 0,
        })}
        onClick={() => {
          accounts.onCreate(createAccount());
        }}>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <AddLineIcon />
        </Stack>
      </Button>
    </Stack>
  );
};
