import {Account} from '../data/accounts';
import {GridRenderCellParams, GridRenderEditCellParams} from '@mui/x-data-grid';
import {formatPercent} from '../lib/formatPercent';
import {TextFieldCell} from '../TextFieldCell';
import {formatAmount} from '../lib/formatAmount';
import React from 'react';
import {useAccounts} from '../hooks/useAccounts';

export const useRenderAccountCell = (id: string | undefined) => {
  const accounts = useAccounts(id);
  const onUpdate = (values: Partial<Account>) => accounts.onUpdate(values);
  return (key: keyof Account, type: 'text' | 'percent' | 'amount') => {
    if (type === 'percent') {
      return {
        editable: true,
        renderCell: (e: GridRenderCellParams<Account>) => {
          return formatPercent(e.value);
        },
        renderEditCell: (params: GridRenderEditCellParams<Account>) => {
          return (
            <TextFieldCell
              value={params.value}
              onChangeValue={(v) => {
                const value = Number(v?.replaceAll(',', '.'));
                onUpdate({...params.row, [key]: value});
              }}
            />
          );
        },
      };
    }
    if (type === 'amount') {
      return {
        minWidth: 120,
        editable: true,
        renderCell: (e: GridRenderCellParams<Account>) => {
          return formatAmount({value: e.value, currency: e.row.currency});
        },
        renderEditCell: (params: GridRenderEditCellParams<Account>) => {
          return (
            <TextFieldCell
              value={params.value}
              onChangeValue={(v) => {
                onUpdate({...params.row, [key]: Number(v)});
              }}
            />
          );
        },
      };
    }
    if (type === 'text') {
      return {
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams<Account>) => {
          return (
            <TextFieldCell
              value={params.value}
              onChangeValue={(v) => {
                onUpdate({...params.row, [key]: v});
              }}
            />
          );
        },
      };
    }
  };
};
