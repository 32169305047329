import React, {useEffect} from 'react';
import useGlobalState from '../../../lib/react-query/useGlobalState';

type ScrollState = {
  prevScrollY: number;
  scrollY: number;
};

export const useScrollListener = (
  id: 'main-scroll',
  scrollRef?: React.MutableRefObject<HTMLElement | undefined>,
) => {
  const {state, setState} = useGlobalState<ScrollState>([id], {
    prevScrollY: 0,
    scrollY: 0,
  });

  const scrollListener = () => {
    const scrollY = scrollRef?.current?.scrollTop ?? 0;
    setState((prev?: ScrollState) => ({
      prevScrollY: prev?.scrollY ?? 0,
      scrollY: scrollY,
    }));
  };

  useEffect(() => {
    const element = scrollRef?.current;
    element?.addEventListener('scroll', scrollListener);
    return () => element?.removeEventListener('scroll', scrollListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef]);

  return {
    scrollUp: state.scrollY < state.prevScrollY,
    scrollDown: state.scrollY > state.prevScrollY,
    scrollY: state.scrollY,
    prevScrollY: state.prevScrollY,
  };
};
