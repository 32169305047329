import React, {MouseEventHandler} from 'react';
import {Challenge, toPaletteKey} from '../../../../data/challenges/challenges';
import {CategoryIcon} from '../../../../components/CategoryIcon';
import {Box, Typography} from '@mui/material';
import {ChallengeStatusIcon} from './ChallengeStatusIcon';
import {UserChallengeStatus} from '../../../../hooks/useUserChallenges';

export const ChallengeListItem: React.FC<{
  challenge: Challenge;
  status?: UserChallengeStatus;
  onClick?: MouseEventHandler;
  onChange?: MouseEventHandler;
}> = ({challenge, status, onClick, onChange}) => {
  return (
    <div
      key={`challenge-${challenge.id}`}
      style={{
        minHeight: 70,
        width: '100%',
        position: 'relative',
        transition: 'all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1)',
        boxShadow:
          '4px 4px 8px 3px rgb(152 162 179 / 15%), 0 2px 2px -1px rgb(152 162 179 / 30%)',
        //     ? '2px 2px 4px -1px rgb(152 162 179 / 15%), 0 2px 2px -1px rgb(152 162 179 / 30%)'
        overflow: 'hidden',
        borderRadius: 4,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Box
          sx={(theme) => {
            const paletteKey = toPaletteKey(challenge.difficulty);
            const color = theme.palette[paletteKey].light;

            return {
              marginLeft: 1.5,
              marginRight: 1.5,
              width: 24,
              height: 24,
              color: color,
            };
          }}>
          <CategoryIcon categoryId={challenge.category} />
        </Box>
        <div
          onClick={onClick}
          style={{
            flex: 1,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 16,
          }}>
          <Typography
            variant={'subtitle1'}
            style={{
              margin: 0,
              fontSize: 16,
              lineHeight: 1.4,
              fontWeight: 600,
              lineClamp: 2,
              height: 48,
              whiteSpace: 'wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {challenge.name}
          </Typography>
        </div>
        <div style={{marginRight: 16, cursor: 'pointer'}} onClick={onChange}>
          <ChallengeStatusIcon status={status} />
        </div>
      </div>
    </div>
  );
};
