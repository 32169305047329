import {BasePage} from '../../../components/BasePage';
import {Stack} from '@mui/material';
import React from 'react';
import {AccountTable} from './AccountTable/AccountTable';
import {PortfolioView} from './PortfolioView';
import {usePortfolios} from './hooks/usePortfolios';
import {ForecastChart} from './ForecastChart/ForecastChart';

export const PortfolioPage: React.FC = () => {
  const portfolios = usePortfolios();
  console.log('portfolios:', portfolios.id);
  return (
    <BasePage>
      <Stack direction={'column'} spacing={2}>
        <PortfolioView />
        <AccountTable id={portfolios.id} />
        <ForecastChart />
      </Stack>
    </BasePage>
  );
};
