/** Overlay.js */
import React, {ForwardedRef} from 'react';

/**
 * Use Overlay as a wrapper for components that need mouse events to be handled.
 * For example: Tooltip, AxisX.
 */
export interface ChartOverlayProps {
  width: number;
  height: number;
  children: React.ReactNode;
}

export const ChartOverlay = React.forwardRef(
  (
    {width, height, children}: ChartOverlayProps,
    ref: ForwardedRef<SVGRectElement>,
  ) => (
    <g>
      {children}
      <rect ref={ref} width={width} height={height} opacity={0} />
    </g>
  ),
);
ChartOverlay.displayName = 'ChartOverlay';
