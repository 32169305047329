import {errorResponse, successResponse} from '../../models/RestResponse';
import Identifiable from '../../models/Identifyable';
import CrudService from './CrudService';
import {randomId} from '../math/randomId';

export const createLocalCrudService = <T extends Identifiable>(
  col: string,
  initial?: T[],
  compare?: (a: T, b: T) => number,
): CrudService<T> => {
  const getElements = (): T[] => {
    const json = localStorage.getItem(col);
    if (json === null || json === undefined) return initial ? [...initial] : [];
    return JSON.parse(json);
  };
  const setElements = (elements: T[]) => {
    if (elements === null || elements === undefined) return;
    const json = JSON.stringify(elements);
    localStorage.setItem(col, json);
  };

  return {
    path: col,
    set: async (request) => {
      request.id = request?.id || randomId();
      const elements = getElements();
      const index = elements.findIndex((e) => e.id === request.id);
      index === -1
        ? elements.unshift(request)
        : (elements[index] = {...request});
      setElements(elements);
      return successResponse(request);
    },
    create: async (e) => {
      e.id = randomId();
      const elements = getElements();
      elements.unshift(e);
      setElements(elements);
      return successResponse(e);
    },
    read: async (id) => {
      const e = getElements().find((e) => e.id === id);
      if (e === undefined) return errorResponse(404);
      return successResponse(e);
    },
    readAll: async () => {
      const elements = getElements();
      if (!!compare) elements.sort(compare);
      return successResponse(elements);
    },
    update: async (request) => {
      const elements = getElements();
      const index = elements.findIndex((e) => e.id === request.id);
      if (index === -1) return errorResponse(404);
      const prev = elements[index];
      elements[index] = {...prev, ...request};
      setElements(elements);
      const value = elements[index];
      return successResponse(value);
    },
    delete: async (element) => {
      const id = typeof element === 'string' ? element : element?.id;
      const elements = getElements();
      const index = elements.findIndex((e) => e.id === id);
      if (index === -1) return errorResponse(404);
      // use splice instead
      setElements(elements.filter((e) => e.id !== id));
      return successResponse(element);
    },
  };
};

export default createLocalCrudService;
