import {Box, Chip, Stack, useMediaQuery, useTheme} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {CategoryIcon} from '../../../../components/CategoryIcon';
import {
  Challenge,
  difficulties,
  toPaletteKey,
} from '../../../../data/challenges/challenges';
import React from 'react';
import {ChallengeStatusIcon} from './ChallengeStatusIcon';
import filterFalsy from '../../../../lib/list/filterFalsy';
import {UserChallengeStatus} from '../../../../hooks/useUserChallenges';
import {GridPaginationModel} from '@mui/x-data-grid/models/gridPaginationProps';

const useIsMobile = () => {
  return !useMediaQuery('(min-width:600px)');
};

export const ChallengeDataGrid: React.FC<{
  rows: Challenge[];
  onClick: (challenge: Challenge) => void;
  getStatus: (challenge: Challenge) => UserChallengeStatus | undefined;
  onChange: (e: React.MouseEvent, challenge: Challenge) => void;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
}> = ({
  rows,
  onClick,
  getStatus,
  onChange,
  paginationModel,
  onPaginationModelChange,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Stack>
      <DataGrid<Challenge>
        rows={rows}
        filterMode={'client'}
        pagination={true}
        paginationMode={'client'}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        initialState={{sorting: {sortModel: [{field: 'state', sort: 'desc'}]}}}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        hideFooterSelectedRowCount={true}
        onCellClick={(e) => {
          if (e.field === 'id') return;
          onClick(e.row);
        }}
        columns={filterFalsy([
          {
            field: 'id',
            headerName: '',
            width: 32,
            sortComparator: (v1: string, v2: string) => {
              const s1 = getStatus({id: v1} as never);
              const s2 = getStatus({id: v2} as never);
              return s1?.localeCompare(s2 ?? '') ?? 0;
            },
            renderCell: (e) => {
              return (
                <div
                  style={{cursor: 'pointer'}}
                  onClick={(event) => onChange(event, e.row)}>
                  <ChallengeStatusIcon status={getStatus(e.row)} />
                </div>
              );
            },
          },
          {field: 'name', headerName: 'Name', flex: 1},
          {
            field: 'category',
            headerName: 'Category',
            align: 'right',
            width: isMobile ? 16 : 32,
            renderCell: (e) => {
              const paletteKey = toPaletteKey(e.row.difficulty);
              const color = theme.palette[paletteKey].light;
              return (
                <Box sx={{color: isMobile ? color : undefined}}>
                  <CategoryIcon categoryId={e.row.category} />
                </Box>
              );
            },
          },
          !isMobile && {
            field: 'difficulty',
            headerName: 'Difficulty',
            sortComparator: (v1, v2) => {
              const index1 = difficulties.indexOf(v1);
              const index2 = difficulties.indexOf(v2);
              return index1 - index2;
            },
            renderCell: (e) => {
              return (
                <Chip
                  label={e.value}
                  sx={{
                    width: 80,
                    ...(e.value === 'easy' && {
                      backgroundColor: theme.palette.success.light,
                      color: theme.palette.success.contrastText,
                    }),
                    ...(e.value === 'medium' && {
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.warning.contrastText,
                    }),
                    ...(e.value === 'hard' && {
                      backgroundColor: theme.palette.error.light,
                      color: theme.palette.error.contrastText,
                    }),
                  }}
                />
              );
            },
          },
        ])}
      />
    </Stack>
  );
};
