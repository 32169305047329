import React from 'react';
import {Challenge, difficulties} from '../../../../data/challenges/challenges';
import {
  Button,
  capitalize,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {SelectField} from '../../../../components/SelectField/SelectField';
import SearchIcon from 'remixicon-react/Search2LineIcon';
import {useNavigate, useParams} from 'react-router-dom';
import {BasePage} from '../../../../components/BasePage';
import {useChallenges} from '../../../../hooks/useChallenges';
import {ChallengeDataGrid} from './ChallengeDataGrid';
import {ChallengeListView} from './ChallengeListView';
import IconButton from '@mui/material/IconButton';
import GridLineIcon from 'remixicon-react/GridLineIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import Tooltip from '@mui/material/Tooltip';
import {useSearchParamForm} from '../../../../lib/hooks/useSearchParamForm';
import SkeletonQuery from '../../../../components/SkeletonQuery';
import {useCategories} from '../../../../hooks/useCategories';
import {SvgIcon} from '../../../../components/SVGIcon';
import {
  statusList,
  useUserChallenges,
} from '../../../../hooks/useUserChallenges';
import {useCurrentUser} from '../../../../hooks/useCurrentUser';
import popConfetti from '../../../../lib/popConfetti';

export const ChallengeListPage = () => {
  const navigate = useNavigate();
  const params = useParams<{categoryId: string}>();
  const categoryId = params?.categoryId;

  const currentUser = useCurrentUser();
  const categories = useCategories();
  const challenges = useChallenges();
  const userChallenges = useUserChallenges(currentUser.id);
  const getStatus = (challenge: Challenge) => {
    const item = userChallenges.getValue((e) => e.challengeId === challenge.id);
    return item?.status;
  };
  const onChangeStatus = (e: React.MouseEvent, challenge: Challenge) => {
    const item = userChallenges.getValue((e) => e.challengeId === challenge.id);
    const next = item?.status === 'done' ? 'none' : 'done';
    if (next === 'done') popConfetti(e);
    userChallenges.upsert({
      id: item?.id ?? '',
      challengeId: challenge.id,
      userId: currentUser.id,
      status: next,
    });
  };

  const form = useSearchParamForm({
    view: '',
    search: '',
    category: '',
    status: '',
    difficulty: '',
    page: '',
    pageSize: '',
  });
  const isListView = form.values.view === 'list';
  const onChangeView = () => {
    form.setValue('view', isListView ? 'grid' : 'list');
  };

  const filtered = challenges.elements.filter((e) => {
    // if category sub-page
    if (categoryId && e.category !== categoryId) {
      return false;
    }

    if (form.values.status && form.values.status !== getStatus(e)) {
      return false;
    }
    if (form.values.category && form.values.category !== e.category) {
      return false;
    }
    if (form.values.difficulty && form.values.difficulty !== e.difficulty) {
      return false;
    }

    if (!form.values.search.length) return true;
    const s = form.values.search.toLowerCase();
    const values = Object.values(e).map((v) => `${v}`.toLowerCase());
    return values.some((v) => v.includes(s));
  });

  const categoryName = categories.getValue((e) => e.id === categoryId)?.name;
  return (
    <BasePage title={categoryName ?? 'Challenges'}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={1} md={0.5}>
          <Stack
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Tooltip title={isListView ? 'Show table' : 'Show list'}>
              <IconButton onClick={onChangeView}>
                {isListView ? <FileListLineIcon /> : <GridLineIcon />}
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={11} md={11.5}>
          <TextField
            fullWidth
            value={form.values.search}
            onChange={(e) => form.setValue('search', e.target.value)}
            placeholder={'search...'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <SelectField
            label={'Status'}
            options={statusList.map((e) => ({
              id: e,
              label: capitalize(e),
            }))}
            value={form.values.status}
            onClear={() => form.setValue('status', '')}
            onChange={(e) => {
              form.setValue('status', e.target.value as string);
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} display={categoryId ? 'none' : undefined}>
          <SkeletonQuery
            variant={'rectangular'}
            width={'100%'}
            isLoading={categories.query.isLoading}>
            <SelectField
              label={'Category'}
              options={categories.query.data?.map((e) => ({
                id: e.id,
                label: e.name,
                icon: <SvgIcon value={e.icon} />,
              }))}
              value={form.values.category}
              onClear={() => form.setValue('category', '')}
              onChange={(e) => {
                form.setValue('category', e.target.value as string);
              }}
            />
          </SkeletonQuery>
        </Grid>
        <Grid item xs={6} md={3}>
          <SelectField
            label={'Difficulty'}
            options={difficulties.map((e) => ({id: e, label: capitalize(e)}))}
            value={form.values.difficulty}
            onClear={() => form.setValue('difficulty', '')}
            onChange={(e) => {
              form.setValue('difficulty', e.target.value as string);
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            variant={'outlined'}
            fullWidth
            sx={{height: '100%'}}
            onClick={() => {
              return navigate(`/main/challenges/new?${form.params}`);
            }}>
            <Typography variant={'button'}>CREATE</Typography>
          </Button>
        </Grid>
      </Grid>
      {!isListView && (
        <ChallengeDataGrid
          rows={filtered}
          onClick={(e) => navigate(`/main/challenges/${e.id}`)}
          getStatus={getStatus}
          onChange={onChangeStatus}
          paginationModel={{
            page: Number(form.values.page || '0'),
            pageSize: Number(form.values.pageSize || '25'),
          }}
          onPaginationModelChange={(model) => {
            window.scrollTo({top: 0, behavior: 'smooth'});
            form.setValue('page', `${model.page}`);
            form.setValue('pageSize', `${model.pageSize}`);
          }}
        />
      )}
      {isListView && (
        <ChallengeListView
          items={filtered}
          onClick={(e) => navigate(`/main/challenges/${e.id}`)}
          getStatus={getStatus}
          onChange={onChangeStatus}
        />
      )}
    </BasePage>
  );
};
