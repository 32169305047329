import React, {MouseEventHandler} from 'react';
import {Box, capitalize, Stack, Typography, useTheme} from '@mui/material';
import {UserCategory} from '../../../../hooks/useUserCategories';
import {SvgIcon} from '../../../../components/SVGIcon';

export const CategoryListItem: React.FC<{
  item: UserCategory;
  onClick?: MouseEventHandler;
}> = ({item, onClick}) => {
  return (
    <div
      key={`challenge-${item.id}`}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        minHeight: 70,
        width: '100%',
        position: 'relative',
        marginBottom: 16,
        transition: 'all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1)',
        boxShadow:
          '4px 4px 8px 3px rgb(152 162 179 / 15%), 0 2px 2px -1px rgb(152 162 179 / 30%)',
        overflow: 'hidden',
        borderRadius: 4,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Box
          sx={() => ({
            marginLeft: 2,
            width: 24,
            height: 24,
          })}>
          <SvgIcon value={item.icon} />
          {/*<CategoryIcon category={item.id as never} />*/}
        </Box>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 16,
          }}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography
              variant={'h3'}
              style={{
                flex: 1,
                fontSize: 18,
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {capitalize(item.name)}
            </Typography>
            <Typography variant={'caption'}>
              {`${item.value} / ${item.valueMax}`}
            </Typography>
          </Stack>
          <PercentBar percent={item.valuePercent} />
        </div>
      </div>
    </div>
  );
};

export const PercentBar: React.FC<{percent: number}> = ({percent}) => {
  const theme = useTheme();

  const getColor = () => {
    if (percent < 0.33) return theme.palette.error.light;
    if (percent < 0.66) return theme.palette.warning.light;
    return theme.palette.success.light;
  };
  const radius = 6;
  return (
    <Box sx={{height: 24, width: '100%', position: 'relative'}}>
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          background: theme.palette.grey['200'],
          borderTopLeftRadius: radius,
          borderBottomLeftRadius: radius,
          borderTopRightRadius: radius,
          borderBottomRightRadius: radius,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: `${Math.min(percent * 100, 100)}%`,
          background: getColor(),
          borderTopLeftRadius: radius,
          borderBottomLeftRadius: radius,
          borderTopRightRadius: radius,
          borderBottomRightRadius: radius,
        }}
      />
      <Typography
        variant={'body1'}
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          textAlign: 'left',
          paddingLeft: 1,
          color: theme.palette.success.contrastText,
        }}>
        {`${Math.round(percent * 100)} %`}
      </Typography>
    </Box>
  );
};
