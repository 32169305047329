import {useCrudListQuery} from '../lib/react-query/useCrudListQuery';
import {createFirestoreCrudService} from '../lib/firebase/createFirestoreCrudService';

export type Review = {
  id: string;
  userId: string;
  text: string;
  rating: number;
  version: string;
  createdAt?: {seconds: number};
  updatedAt?: {seconds: number};
};

export const useReviews = () => {
  return useCrudListQuery<Review>(
    createFirestoreCrudService(`reviews`, (v1, v2) => {
      const t1 = v1.updatedAt?.seconds ?? 0;
      const t2 = v2.updatedAt?.seconds ?? 0;
      return t2 - t1;
    }),
  );
};
