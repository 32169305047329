import React from 'react';
import {Box, Tooltip, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import {useNavigate} from 'react-router-dom';

export interface BasePageProps extends React.PropsWithChildren {
  title?: string;
}

export const BasePage: React.FC<BasePageProps> = ({title, children}) => {
  const navigate = useNavigate();
  return (
    <div style={{width: '100%', height: '100%'}}>
      <Box
        sx={(theme) => ({
          padding: 2,
          background: theme.palette.background.paper,
        })}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            mb: 2,
          }}>
          <Tooltip title={'Go back'}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowLeftLineIcon />
            </IconButton>
          </Tooltip>
          <Typography variant={'h5'}>{title}</Typography>
        </Box>
        {children}
      </Box>
    </div>
  );
};
