import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Box, Stack, Typography, useTheme} from '@mui/material';
import {navigationList} from '../../../constants/NavigationList';
import {useScrollListener} from './useScrollListener';

export const MainBottomNavBar: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();

  const {scrollUp, scrollY} = useScrollListener('main-scroll');
  const height = 80;
  const show = scrollUp || scrollY < 160;
  const transform = show ? undefined : `translateY(${height}px)`;

  return (
    <Box
      component={'nav'}
      sx={{
        position: 'fixed',
        display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'none'},
        height: height,
        minHeight: height,
        transform: transform,
        transition: 'all .4s cubic-bezier(.25,.8,.5,1)',
        px: 2,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'flex-end',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.9,
      }}>
      {navigationList.map((e) => {
        const isActive = location.pathname === e.route;

        return (
          <Stack
            component={Link}
            to={e.route}
            id={e.name}
            key={e.route}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            position={'relative'}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                borderRadius: '10px',
              }}>
              <e.icon />
            </Box>
            <Typography
              typography={'caption'}
              sx={{
                mb: 1,
                color: isActive ? theme.palette.primary.main : undefined,
              }}>
              {e.name}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
};
