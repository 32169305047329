import React from 'react';
import {DrawerButton} from './DrawerButton';
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon';
import Routes from '../../../constants/Routes';
import {useNavigate} from 'react-router-dom';

export const DrawerLogoutButton: React.FC<{open: boolean}> = ({open}) => {
  const navigate = useNavigate();
  return (
    <DrawerButton
      route="/"
      open={open}
      name={'Logout'}
      icon={<LogoutBoxLineIcon />}
      onClick={async () => {
        localStorage.clear();
        navigate(Routes.landing);
      }}
    />
  );
};
