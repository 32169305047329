import React, {useState} from 'react';

import TextField from '@mui/material/TextField/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import VisibilityIcon from 'remixicon-react/Eye2LineIcon';
import VisibilityOffIcon from 'remixicon-react/EyeCloseLineIcon';
import Button from '@mui/material/Button';
import Routes from '../../constants/Routes';
import auth from '../../lib/firebase/auth';
import useLocalAuthentication, {
  useLocalRoute,
} from '../../hooks/useLocalAuthentication';
import snackbar from '../../providers/snackbar';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';

export interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [remember, setRemember] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {email: '', password: ''},
  });

  const [route] = useLocalRoute();
  const {setCredentials} = useLocalAuthentication((e) => {
    if (e.success) {
      // navigate to home
      navigate(Routes.home);
      // navigate to last route
      if (route) navigate(route);
    }
  });

  const onSubmit = async (values: LoginFormValues) => {
    const response = await auth.signInWithEmail(values.email, values.password);
    if (!response.success) {
      snackbar.showFeedback(response.feedback);
      return;
    }

    if (remember) setCredentials(values.email, values.password);
    navigate(Routes.home);
  };

  const onSkip = async () => {
    const response = await auth.signInAnonymously();
    if (response.success) {
      navigate(Routes.home);
    }
  };

  return (
    <form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}>
        <h2 style={{flex: 1, margin: 0}}>Login</h2>
        <Tooltip title={'Login anonymously'}>
          <Button style={{padding: 0}} color="primary" onClick={onSkip}>
            Skip
          </Button>
        </Tooltip>
      </div>
      <TextField
        {...form.register('email')}
        variant="filled"
        margin="normal"
        required
        fullWidth
        id="email"
        name="email"
        label="Email"
        type="email"
        autoComplete="email"
        autoFocus
      />
      <Box position="relative">
        <TextField
          {...form.register('password')}
          variant="filled"
          margin="normal"
          fullWidth
          required
          id="password"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
        />
        <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
          <IconButton
            size="medium"
            sx={(theme) => ({
              position: 'absolute',
              right: '0px',
              top: '0',
              margin: '20px 4px 4px 4px',
              color: theme.palette.common.black,
            })}
            onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon color="#aaaaaa" />
            )}
          </IconButton>
        </Tooltip>
      </Box>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 12,
          marginBottom: 12,
        }}>
        <FormControlLabel
          label="Remember me"
          control={
            <Checkbox
              checked={remember}
              onChange={() => setRemember(!remember)}
              color="primary"
            />
          }
        />
        <Button
          color="primary"
          onClick={() => {
            navigate(Routes.forgotPassword);
          }}>
          Forgot your password?
        </Button>
      </div>

      <Button
        onClick={form.handleSubmit(onSubmit)}
        aria-label="submit"
        variant="contained"
        color={'primary'}
        fullWidth
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          borderRadius: 40,
        }}>
        Login
      </Button>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 16,
        }}>
        <Button
          color="primary"
          onClick={() => {
            navigate(Routes.register);
          }}>
          Register a new user?
        </Button>
      </div>
    </form>
  );
};
