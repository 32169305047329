import Paper from '@mui/material/Paper';
import {Grid, Stack} from '@mui/material';
import {usePortfolios} from './hooks/usePortfolios';
import React from 'react';
import {SelectField} from '../../../components/SelectField/SelectField';
import {formatAmount} from './lib/formatAmount';
import {formatPercent} from './lib/formatPercent';
import {EditTextField} from './EditTextField';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import IconButton from '@mui/material/IconButton';

export const PortfolioView: React.FC = () => {
  const portfolios = usePortfolios();

  return (
    <Paper>
      <Stack p={2} direction={'column'}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <SelectField
                value={portfolios?.id}
                onChange={(e) => {
                  portfolios.setId(() => `${e.target.value}`);
                }}
                options={portfolios.query.data?.map((e) => ({
                  id: e.id,
                  label: e.name,
                }))}
              />
              <IconButton
                onClick={() =>
                  portfolios.onCreate({
                    id: '',
                    name: '',
                    years: 30,
                    country: 'DK',
                    inflation: 0,
                    expenses: {value: 0, currency: 'DKK'},
                  })
                }>
                <AddLineIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item md={4} xs={6}>
            <EditTextField
              name={'Name'}
              value={portfolios.current?.name}
              onChangeValue={(v) => {
                portfolios.onUpdate({...portfolios.current, name: v});
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <EditTextField
              name={'Country'}
              value={portfolios.current?.country}
              onChangeValue={(v) => {
                portfolios.onUpdate({
                  ...portfolios.current,
                  country: v as never,
                });
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <EditTextField
              name={'Monthly expenses'}
              text={formatAmount(portfolios.current?.expenses)}
              value={portfolios.current?.expenses?.value}
              onChangeValue={(v) => {
                portfolios.onUpdate({
                  ...portfolios.current,
                  expenses: {value: Number(v), currency: 'DKK'},
                });
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <EditTextField
              name={'Inflation'}
              text={formatPercent(portfolios.current?.inflation)}
              value={portfolios.current?.inflation}
              onChangeValue={(v) => {
                portfolios.onUpdate({
                  ...portfolios.current,
                  inflation: Number(v),
                });
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <EditTextField
              name={'Years'}
              value={portfolios.current?.years}
              onChangeValue={(v) => {
                portfolios.onUpdate({...portfolios.current, years: Number(v)});
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};
