import React from 'react';
import {useForm} from 'react-hook-form';
import {Challenge, difficulties} from '../../../../data/challenges/challenges';
import {
  Button,
  capitalize,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {SelectField} from '../../../../components/SelectField/SelectField';
import {BasePage} from '../../../../components/BasePage';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useChallenges} from '../../../../hooks/useChallenges';
import snackbar from '../../../../providers/snackbar';
import {useAlertDialog} from '../../../../providers/AlertDialogProvider';
import {useCategories} from '../../../../hooks/useCategories';
import SkeletonQuery from '../../../../components/SkeletonQuery';
import {SvgIcon} from '../../../../components/SVGIcon';
import {useCurrentUserChallenges} from '../../../../hooks/useCurrentUserChallenges';
import {UserChallengeStatus} from '../../../../hooks/useUserChallenges';
import {useCurrentUser} from '../../../../hooks/useCurrentUser';

export const ChallengePage = () => {
  const [search] = useSearchParams();
  const {challengeId} = useParams<{challengeId: string}>();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const categories = useCategories();
  const challenges = useChallenges();
  const challenge = challenges.query.data?.find((e) => e.id === challengeId);
  const userChallenges = useCurrentUserChallenges();
  const userChallenge = userChallenges.getValue(
    (e) => e.challengeId === challengeId,
  );

  const alert = useAlertDialog();

  const isEdit = !!challenge?.id;
  const canEdit = !isEdit || (isEdit && challenge.createdBy === currentUser.id);
  const setStatus = async (s: UserChallengeStatus) => {
    await userChallenges.upsert({
      id: userChallenge?.id ?? '',
      status: s,
      challengeId: challengeId ?? '',
      userId: currentUser.id,
    });
    navigate(-1);
  };

  const form = useForm<Challenge>({
    mode: 'onBlur',
    defaultValues: {
      name: challenge?.name ?? '',
      description: challenge?.description ?? '',
      category: challenge?.category ?? (search.get('category') as never),
      difficulty: challenge?.difficulty ?? 'easy',
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    if (!values.name) return snackbar.warning('Invalid name');
    if (!values.category) return snackbar.warning('Invalid category');
    if (!values.difficulty) return snackbar.warning('Invalid difficulty');

    await challenges.upsert({...challenge, ...values});
    snackbar.success('Challenge updated!');
    navigate(-1);
  });

  return (
    <BasePage title={'Challenge'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={!canEdit}
            {...form.register('name')}
            label={'Name'}
            type={'text'}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <SkeletonQuery
            variant={'rectangular'}
            width={'100%'}
            isLoading={categories.query.isLoading}>
            <SelectField
              disabled={!canEdit}
              required
              {...form.register('category')}
              value={form.watch('category')}
              label={'Category'}
              options={categories.query.data?.map((e) => {
                return {
                  id: e.id,
                  label: e.name,
                  icon: <SvgIcon value={e.icon} />,
                };
              })}
            />
          </SkeletonQuery>
        </Grid>
        <Grid item xs={6}>
          <SelectField
            disabled={!canEdit}
            {...form.register('difficulty')}
            value={form.watch('difficulty')}
            label={'Difficulty'}
            options={difficulties.map((e) => ({id: e, label: capitalize(e)}))}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!canEdit}
            {...form.register('description')}
            label={'Description'}
            type={'text'}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Button
              fullWidth
              variant={
                userChallenge?.status === 'none' ? 'contained' : 'outlined'
              }
              color={'info'}
              disabled={!isEdit}
              onClick={() => setStatus('none')}>
              NONE
            </Button>
            <Button
              fullWidth
              variant={
                userChallenge?.status === 'active' ? 'contained' : 'outlined'
              }
              color={'warning'}
              disabled={!isEdit}
              onClick={() => setStatus('active')}>
              ACTIVE
            </Button>
            <Button
              fullWidth
              variant={
                userChallenge?.status === 'done' ? 'contained' : 'outlined'
              }
              color={'success'}
              disabled={!isEdit}
              onClick={() => setStatus('done')}>
              DONE
            </Button>
            <Button
              fullWidth
              variant={
                userChallenge?.status === 'declined' ? 'contained' : 'outlined'
              }
              color={'error'}
              disabled={!isEdit}
              onClick={() => setStatus('declined')}>
              DECLINED
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant={'outlined'}
            color={'error'}
            fullWidth
            disabled={!canEdit}
            onClick={async () => {
              alert.warning({
                header: 'Delete achievement',
                title: 'Are you sure?',
                subtitle: 'This will delete the achievement permanently',
                actions: [
                  {
                    title: 'Cancel',
                    color: 'primary',
                    onClick: alert.onClose,
                  },
                  {
                    title: 'Confirm',
                    color: 'secondary',
                    onClick: async () => {
                      await challenges.onDelete(challenge?.id);
                      alert.onClose();
                      snackbar.success('Challenge deleted!');
                      navigate(-1);
                    },
                  },
                ],
              });
            }}>
            <Typography variant={'button'}>DELETE</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={!canEdit}
            onClick={onSubmit}>
            <Typography variant={'button'}>SAVE</Typography>
          </Button>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default ChallengePage;
