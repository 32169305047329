import React from 'react';
import {BasePage} from '../../../../components/BasePage';
import {CategoryListView} from './CategoryListView';

export const CategoryListPage = () => {
  return (
    <BasePage title={'Categories'}>
      <CategoryListView />
    </BasePage>
  );
};
