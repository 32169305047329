import {ChallengeCategoryId} from './categories';

export const difficulties = ['easy', 'medium', 'hard'] as const;
export type ChallengeDifficulty = typeof difficulties[number];

export const toPaletteKey = (e: ChallengeDifficulty) => {
  if (e === 'easy') return 'success';
  if (e === 'medium') return 'warning';
  if (e === 'hard') return 'error';
  return 'info';
};

export type Challenge = {
  id: string;
  name: string;
  description?: string;
  category: ChallengeCategoryId;
  difficulty: ChallengeDifficulty;
};
