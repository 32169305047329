import React, {useState} from 'react';
import {TextFieldCell} from './TextFieldCell';
import {Button, Stack, Typography} from '@mui/material';

export const EditTextField: React.FC<{
  name: string;
  text?: string;
  value: string | number | undefined;
  onChangeValue: (v: string) => void;
}> = ({name, text, value, onChangeValue}) => {
  const [isEdit, setIsEdit] = useState(false);

  if (isEdit) {
    return (
      <TextFieldCell
        value={value}
        onBlur={() => setIsEdit(false)}
        onChangeValue={(v) => {
          onChangeValue(v);
          setIsEdit(false);
        }}
      />
    );
  }

  return (
    <Button fullWidth variant={'text'} onClick={() => setIsEdit(true)}>
      <Stack flex={1} direction={'column'} alignItems={'flex-start'}>
        <Typography variant={'overline'}>{name}</Typography>
        <Typography>{text ?? value}</Typography>
      </Stack>
    </Button>
  );
};
