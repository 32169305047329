import React from 'react';
import IconButton from '@mui/material/IconButton';
import {SvgIcon} from './SVGIcon';

export const SVGIconInput: React.FC<{
  value?: string;
  onChange?: (value: string | undefined) => void;
  disabled?: boolean;
}> = ({value, onChange, disabled}) => {
  return (
    <div>
      <input
        id="svg-input"
        type="file"
        accept=".svg"
        onChange={(event) => {
          const file = event.target.files?.[0];
          if (!file || file.type !== 'image/svg+xml') return;
          const reader = new FileReader();
          reader.onload = () => {
            if (typeof reader.result !== 'string') return;
            onChange?.(reader.result);
          };
          reader.readAsText(file);
        }}
        style={{display: 'none'}}
      />
      <IconButton
        disabled={disabled}
        onClick={() => document.getElementById('svg-input')?.click()}>
        <SvgIcon value={value} />
      </IconButton>
    </div>
  );
};
