import {RegisterUserRequest} from '../../pages/auth/RegisterUserForm';
import RestResponse, {
  errorResponse,
  successResponse,
} from '../../models/RestResponse';
import UserData from '../../models/UserData';
import {db, firebaseAuth} from './firebase';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {randomId} from '../math/randomId';

export interface AuthServiceType {
  registerWithEmail: (request: RegisterUserRequest) => RestResponse<UserData>;
  signInWithEmail: (email: string, password: string) => RestResponse<UserData>;
  signInAnonymously: () => RestResponse<UserData>;
  forgotPassword: (email: string) => RestResponse<undefined>;
  resetPassword: (password: string, token: string) => RestResponse<undefined>;
}

export const auth: AuthServiceType = {
  registerWithEmail: async (request) => {
    const response = await createUserWithEmailAndPassword(
      firebaseAuth,
      request.email,
      request.password,
    );
    const currentUserId = response.user.uid;
    if (currentUserId === undefined) {
      return errorResponse(401, {
        severity: 'warning',
        message: 'A user with this email already exists',
      });
    }

    const user: UserData = {
      id: currentUserId,
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    await setDoc(doc(db, 'users', currentUserId), user);

    return successResponse(user);
  },
  signInWithEmail: async (email, password) => {
    const response = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    ).catch((e) => {
      return e;
    });

    if (response?.code === 'auth/user-not-found') {
      return errorResponse(500, {
        severity: 'warning',
        error: response?.code,
        message: 'We could not find a user with the given email',
      });
    }

    if (response?.code === 'auth/wrong-password') {
      return errorResponse(500, {
        severity: 'warning',
        error: response?.code,
        message: 'The given password was incorrect',
      });
    }

    if (response?.code !== undefined) {
      return errorResponse(500, {
        severity: 'warning',
        error: response?.code,
        message: response?.code,
      });
    }

    const currentUserId = response.user.uid;
    const snapshot = await getDoc(doc(db, 'users', currentUserId));
    const user = snapshot.data() as UserData;

    if (user === undefined) {
      return errorResponse(404, {
        severity: 'warning',
        message: 'User not found',
      });
    }

    return successResponse(user);
  },
  signInAnonymously: async () => {
    const response = await signInAnonymously(firebaseAuth);
    console.log('response');
    console.log(response);
    const currentUserId = response.user.uid;
    const user: UserData = {
      id: currentUserId,
      firstName: 'Anonymous',
      lastName: randomId(),
      email: '',
      phoneNumber: '',
    };

    await setDoc(doc(db, 'users', currentUserId), user);

    return successResponse(user);
  },
  forgotPassword: async (email: string) => {
    await sendPasswordResetEmail(firebaseAuth, email);
    return successResponse(
      undefined,
      'We have sent you an email with instructions to reset your password',
    );
  },
  resetPassword: async (password: string, token: string) => {
    throw new Error('Not implemented' + {password, token});
  },
};

export default auth;
