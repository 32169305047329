import React from 'react';
import DOMPurify from 'dompurify';

export interface SVGIconProps {
  value?: string;
  color?: string;
  width?: string;
  height?: string;
}

export const SvgIcon: React.FC<SVGIconProps> = ({
  value,
  color = 'inherit',
  height = 24,
  width = 24,
}) => {
  return (
    <div
      style={{width, height, color}}
      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value ?? '')}}
    />
  );
};
