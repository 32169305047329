import {Skeleton, SkeletonProps} from '@mui/material';
import React from 'react';

export type SkeletonQueryProps = SkeletonProps & {
  isLoading: boolean;
};

export const SkeletonQuery: React.FC<SkeletonQueryProps> = ({
  isLoading,
  children,
  ...rest
}) => {
  if (isLoading) return <Skeleton {...rest}>{children}</Skeleton>;
  return <>{children}</>;
};

export default SkeletonQuery;
