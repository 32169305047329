import React from 'react';
import {TextField} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';

export type TextFieldCellProps = TextFieldProps & {
  onChangeValue?: (v: string) => void;
};

export const TextFieldCell: React.FC<TextFieldCellProps> = ({
  value,
  onChangeValue,
}) => {
  return (
    <TextField
      autoFocus
      fullWidth
      defaultValue={value}
      type={'text'}
      onKeyDown={(e) => {
        if (e.code !== 'Enter') return;
        const target = e.target as HTMLInputElement;
        onChangeValue?.(target.value);
      }}
    />
  );
};
