import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import {AlertDialog, AlertDialogProps} from './AlertDialog';

interface AlertDialogState {
  open?: boolean;
  variant?: AlertDialogProps['variant'];
  header?: AlertDialogProps['header'];
  title?: AlertDialogProps['title'];
  subtitle?: AlertDialogProps['subtitle'];
  actions?: AlertDialogProps['actions'];
}

const AlertDialogContext = createContext<{
  error: (params: Omit<AlertDialogState, 'variant' | 'icon'>) => void;
  onClose: () => void;
  setState: (params: AlertDialogState | null) => void;
  state: AlertDialogState | null;
  success: (params: Omit<AlertDialogState, 'variant' | 'icon'>) => void;
  warning: (params: Omit<AlertDialogState, 'variant' | 'icon'>) => void;
}>({
  error: () => {},
  onClose: () => {},
  setState: () => {},
  state: null,
  success: () => {},
  warning: () => {},
});

export const AlertDialogProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<AlertDialogState | null>(null);

  const handleClose = () => {
    setState((prev) => ({...prev, open: false}));
  };

  const error = (params: Omit<AlertDialogState, 'variant' | 'icon'>) => {
    setState({open: true, variant: 'error', ...params});
  };

  const success = (params: Omit<AlertDialogState, 'variant' | 'icon'>) => {
    setState({open: true, variant: 'success', ...params});
  };

  const warning = (params: Omit<AlertDialogState, 'variant' | 'icon'>) => {
    setState({open: true, variant: 'warning', ...params});
  };

  return (
    <AlertDialogContext.Provider
      value={{state, setState, success, error, warning, onClose: handleClose}}>
      {children}
      <AlertDialog
        open={!!state?.open}
        onClose={handleClose}
        variant={state?.variant}
        header={state?.header}
        title={state?.title}
        subtitle={state?.subtitle}
        actions={state?.actions}
      />
    </AlertDialogContext.Provider>
  );
};

export const useAlertDialog = () => useContext(AlertDialogContext);
