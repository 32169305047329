import React from 'react';
import {Grid} from '@mui/material';
import {CategoryListItem} from './CategoryListItem';
import {useUserCategories} from '../../../../hooks/useUserCategories';
import {useNavigate} from 'react-router-dom';
import useModifierKey from '../../../../lib/hooks/useModifierKey';

export const CategoryListView = () => {
  const navigate = useNavigate();
  const isMetaKey = useModifierKey();
  const categories = useUserCategories();

  const data = categories.query.data ?? [];
  const sum = data.reduce((r, e) => e.value + r, 0);
  const sumMax = data.reduce((r, e) => e.valueMax + r, 0);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CategoryListItem
          onClick={() => {
            if (isMetaKey) return navigate(`/main/categories/new`);
            return navigate(`/main/challenges`);
          }}
          item={{
            id: 'all',
            name: 'All',
            icon: SVGIconAll,
            value: sum,
            valueMax: sumMax,
            valuePercent: sum / sumMax,
          }}
        />
      </Grid>
      {categories.query.data?.map((item) => {
        return (
          <Grid key={item.id} item xs={12} sm={12} md={6} lg={4} xl={3}>
            <CategoryListItem
              onClick={() => {
                if (isMetaKey) return navigate(`/main/categories/${item.id}`);
                navigate(`/main/challenges/category/${item.id}`);
              }}
              item={item}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const SVGIconAll =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M5 18L12.6796 12L5 6V4H19V6H8.26348L16 12L8.26348 18H19V20H5V18Z"></path></svg>';
