import React from 'react';
import {useForm} from 'react-hook-form';
import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import {BasePage} from '../../../../components/BasePage';
import {useNavigate, useParams} from 'react-router-dom';
import snackbar from '../../../../providers/snackbar';
import {useAlertDialog} from '../../../../providers/AlertDialogProvider';
import {useCategories} from '../../../../hooks/useCategories';
import {Category} from '../../../../data/challenges/categories';
import {SVGIconInput} from '../../../../components/SVGIconInput';
import {useCurrentUser} from '../../../../hooks/useCurrentUser';

export const CategoryPage = () => {
  const {categoryId} = useParams<{categoryId: string}>();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const categories = useCategories();
  const category = categories.query.data?.find((e) => e.id === categoryId);
  const alert = useAlertDialog();

  const isEdit = !!category?.id;
  const canEdit = !isEdit || (isEdit && category.createdBy === currentUser.id);
  const form = useForm<Category>({
    mode: 'onBlur',
    defaultValues: {
      id: category?.id ?? '',
      name: category?.name ?? '',
      description: category?.description ?? '',
      icon: category?.icon ?? '',
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    if (!values.name) return snackbar.warning('Invalid name');
    isEdit
      ? await categories.onUpdate({...category, ...values})
      : await categories.setValue({...category, ...values});
    snackbar.success('Category updated!');
    navigate(-1);
  });

  return (
    <BasePage title={'Category'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={isEdit}
            {...form.register('id')}
            label={'Id'}
            type={'text'}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <SVGIconInput
              value={form.watch('icon')}
              onChange={(value) => form.setValue('icon', value)}
              disabled={!canEdit}
            />
          </Box>
        </Grid>
        <Grid item xs={11}>
          <TextField
            disabled={!canEdit}
            {...form.register('name')}
            label={'Name'}
            type={'text'}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!canEdit}
            {...form.register('description')}
            label={'Description'}
            type={'text'}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant={'outlined'}
            color={'error'}
            fullWidth
            disabled={!canEdit}
            onClick={async () => {
              alert.warning({
                header: 'Delete achievement',
                title: 'Are you sure?',
                subtitle: 'This will delete the achievement permanently',
                actions: [
                  {
                    title: 'Cancel',
                    color: 'primary',
                    onClick: alert.onClose,
                  },
                  {
                    title: 'Confirm',
                    color: 'secondary',
                    onClick: async () => {
                      await categories.onDelete(categoryId);
                      alert.onClose();
                      snackbar.success('Challenge deleted!');
                      navigate(-1);
                    },
                  },
                ],
              });
            }}>
            <Typography variant={'button'}>DELETE</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={!canEdit}
            onClick={onSubmit}>
            <Typography variant={'button'}>SAVE</Typography>
          </Button>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default CategoryPage;
