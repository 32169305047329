import Identifiable from '../../models/Identifyable';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import CrudService from '../firebase/CrudService';
import snackbar from '../../providers/snackbar';
import {RestErrorResponse} from '../../models/RestResponse';

export const useCrudQuery = <T extends Identifiable>(
  id: string | undefined,
  service: CrudService<T>,
  fallback: T | undefined = undefined,
) => {
  const queryClient = useQueryClient();
  const queryKey = [service.path, id];

  const query = useQuery<T | undefined>({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await service.read(id);
      if (!response.success) return fallback;
      return response.value;
    },
    staleTime: Infinity,
  });

  const showError = (response: RestErrorResponse) => {
    return snackbar.showFeedback(response.feedback);
  };

  return {
    query: query,
    value: query.data ?? undefined,
    setValue: async (value: T | ((prev: T) => T)) => {
      if (!service.set) return;
      const previous = queryClient.getQueryData(queryKey) as T;
      const nextValue = typeof value === 'function' ? value(previous) : value;

      await queryClient.cancelQueries({queryKey});
      const response = await service.set(nextValue);
      if (!response.success) {
        return showError(response);
      }

      // naively update query state
      nextValue.id = response.value.id;
      const next = queryClient.setQueryData(queryKey, () => {
        return nextValue;
      });
      return {previous, next};
    },
    onCreate: async (value: T) => {
      await queryClient.cancelQueries({queryKey});
      const response = await service.create(value);
      if (!response.success) {
        return showError(response);
      }

      // naively update query state
      value.id = response.value.id;
      const previous = queryClient.getQueryData(queryKey);
      const next = queryClient.setQueryData(queryKey, () => {
        return value;
      });
      return {previous, next};
    },
    onUpdate: async (request: Partial<T>) => {
      await queryClient.cancelQueries({queryKey});
      const response = await service.update(request);
      if (!response.success) {
        return showError(response);
      }

      // naively update query state
      const value = response.value;
      const previous = queryClient.getQueryData(queryKey);
      const next = queryClient.setQueryData(queryKey, () => {
        return value;
      });
      return {previous, next};
    },
    onDelete: async (value: {id?: string} | string) => {
      await queryClient.cancelQueries({queryKey});

      const response = await service.delete(value);
      if (!response.success) {
        return showError(response);
      }

      queryClient.setQueryData(queryKey, () => undefined);
    },
  };
};
