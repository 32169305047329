import Identifiable from '../../models/Identifyable';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import CrudService from '../firebase/CrudService';
import {RestErrorResponse} from '../../models/RestResponse';
import snackbar from '../../providers/snackbar';

export const useCrudListQuery = <T extends Identifiable>(
  service: CrudService<T>,
) => {
  const queryClient = useQueryClient();
  const queryKey = [service.path];

  const query = useQuery<T[]>({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await service.readAll();
      if (!response.success) return [];
      return response.value;
    },
    staleTime: Infinity,
  });

  const showError = (response: RestErrorResponse) => {
    return snackbar.showFeedback(response.feedback);
  };

  const onCreate = async (value: T) => {
    await queryClient.cancelQueries({queryKey});
    const response = await service.create(value);
    if (!response.success) {
      return showError(response);
    }

    // naively update query state
    value.id = response.value.id;
    const previous = queryClient.getQueryData(queryKey);
    const next = queryClient.setQueryData(queryKey, (old: unknown) => {
      if (!Array.isArray(old)) return [value];
      return [value, ...old];
    });
    return {previous, next};
  };

  const onUpdate = async (value: Partial<T>) => {
    await queryClient.cancelQueries({queryKey});
    const prev = query.data?.find((e) => e.id === value.id);
    const next = {...prev, ...value};
    const response = await service.update(next);
    if (!response.success) return showError(response);

    // naively update query state
    const previous = queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, (old: unknown) => {
      if (!Array.isArray(old)) return [next];
      const index = old.findIndex((e) => e.id === value.id);
      const state = [...old];
      index === -1 ? state.unshift(next) : (state[index] = next);
      if (service.compare) return state.sort(service.compare);
      return state;
    });
    return {previous};
  };

  const upsert = async (value: T) => {
    if (!value.id) return onCreate(value);
    return onUpdate(value);
  };

  const getValue = (predicate: (item: T) => boolean) => {
    return query.data?.find(predicate);
  };

  return {
    query: query,
    elements: query.data ?? [],
    onCreate,
    onUpdate,
    upsert,
    getValue,
    setValue: async (value: T) => {
      if (!service.set) return;

      await queryClient.cancelQueries({queryKey});
      const response = await service.set(value);
      if (!response.success) return showError(response);
      await query.refetch();
      return response;
    },
    onDelete: async (value: {id?: string} | string | undefined) => {
      await queryClient.cancelQueries({queryKey});
      const response = await service.delete(value);
      if (!response.success) {
        return showError(response);
      }

      // naively update query state
      queryClient.setQueryData(queryKey, (old: unknown) => {
        if (!Array.isArray(old)) return [];
        const id = typeof value === 'string' ? value : value?.id;
        return old.filter((e) => e.id !== id);
      });
    },
  };
};
