import React, {useEffect, useState} from 'react';
import {InputBase} from '@mui/material';
import {randomId} from '../../lib/math/randomId';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import theme from '../../constants/theme';
import MenuLineIcon from 'remixicon-react/MenuLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import {useCrudQuery} from '../../lib/react-query/useCrudQuery';
import {useParams} from 'react-router-dom';
import createLocalCrudService from '../../lib/firebase/createLocalCrudService';

export interface CheckList {
  id: string;
  name: string;
  items: CheckListItem[];
}

export interface CheckListItem {
  id: string;
  type: 'check' | 'text';
  name: string;
  description?: string;
  color?: string;
  checked?: boolean;
}

let focused = true;
let focusIndex = 0;
const setNextFocus = (index: number) => {
  focused = false;
  focusIndex = index;
};
const setFocus = (index: number) => {
  document.getElementById(`field-${index}`)?.focus();
};

const reorder = <T,>(
  list: T[],
  startIndex?: number,
  endIndex?: number,
): T[] => {
  if (startIndex === undefined || endIndex === undefined) return list;
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const ChecklistPage: React.FC = () => {
  // const currentUser = useCurrentUser();
  // const collection = `users/${currentUser?.id}/habits`;
  //
  // const {elements, onUpdate, onDelete} = useCrudListQuery<Habit>(crudService(collection));
  // elements.sort((a, b) => {
  //     if (!a.startTime || !b.startTime) return 0;
  //     return a.startTime.localeCompare(b.startTime);
  // })
  // const [todoList, setTodoList] = useState(elements.filter(e => e.dayOfWeek === nowDayName || e.isDaily).map((e) => ({
  //     id: e.id,
  //     text: e.name,
  //     checked: false,
  // })));

  const {checklistId} = useParams<{checklistId: string}>();
  const {value: checklist, setValue: setCheckList} = useCrudQuery<CheckList>(
    checklistId,
    createLocalCrudService('checklist'),
    {
      id: checklistId ?? '',
      name: '(Unnamed checklist)',
      items: [],
    },
  );

  const [, setViewUpdate] = useState(false);
  const updateView = () => {
    setViewUpdate((old) => !old);
  };

  useEffect(() => {
    if (focused) return;
    focused = true;
    if (!checklist?.items?.length) return;
    setFocus(focusIndex);
  }, [checklist]);

  if (checklist === undefined) {
    return (
      <div style={{width: '100vw', backgroundColor: 'white'}}>
        <div style={{height: 56, backgroundColor: '#F7F7F7'}} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}>
          Checklist not found
        </div>
      </div>
    );
  }

  // const checkItem = (index: number) => {
  //   return updateItem(index, (item) => ({
  //     ...item,
  //     checked: !item.checked,
  //   }));
  // };

  const resetItemType = (index: number) => {
    setNextFocus(index);
    return updateItem(index, (item) => ({
      ...item,
      type: 'text',
    }));
  };

  const updateItem = (
    index: number,
    update: (item: CheckListItem) => CheckListItem,
  ) => {
    setCheckList((prev: CheckList) => {
      return {
        id: prev.id,
        name: prev.name,
        items: prev.items.map((e, i) => {
          if (index === i) return update(e);
          return {...e};
        }),
      };
    });
  };

  const removeItem = (index: number) => {
    setNextFocus(index - 1);
    setCheckList((prev) => {
      return {
        id: prev.id,
        name: prev.name,
        items: prev.items.filter((e, i) => i !== index),
      };
    });
  };

  const addItem = (index: number, type: 'check' | 'text') => {
    setNextFocus(index + 1);
    setCheckList((prev) => {
      const items = [...prev.items];
      items.splice(index + 1, 0, {
        id: randomId(),
        type: type,
        name: '',
        checked: false,
        description: '',
        color: '',
      });
      return {
        id: prev.id,
        name: prev.name,
        items: items,
      };
    });
  };

  const renderItem = (item: CheckListItem, index: number) => {
    const isFocused = document.activeElement?.id === `field-${index}`;
    // const isFocused = focusIndex === index;

    return (
      <div
        key={item.id}
        onClick={() => {
          updateView();
        }}
        style={{
          display: 'flex',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 4,
          paddingBottom: 4,
        }}>
        <MenuLineIcon
          color={'#ccc'}
          style={{
            width: 24,
            height: 24,
            marginRight: 8,
            opacity: isFocused ? 1 : 0,
          }}
        />
        {item.type === 'check' && <div></div>}
        <InputBase
          id={`field-${index}`}
          defaultValue={item.name}
          // value={item.name}
          fullWidth
          multiline={item.type === 'text'}
          style={{
            padding: 0,
            fontSize: 16,
            fontWeight: 400,
            color: item.checked ? theme.colors.textGrey : theme.colors.black,
            textDecoration: item.checked ? 'line-through' : undefined,
          }}
          onChange={(e) => {
            updateItem(index, (prev) => ({
              ...prev,
              name: e.currentTarget.value,
            }));
          }}
          inputProps={{
            style: {padding: 0},
            onKeyDown: (e) => {
              const value = e.currentTarget.value;
              const isEmpty = value.trim().length === 0;
              const keyLower = e.key.toLowerCase();

              if (e.shiftKey) {
                return;
              }

              // enter and backspace
              if (item.type === 'check') {
                if (keyLower === 'backspace' && isEmpty) {
                  resetItemType(index);
                  e.preventDefault();
                }
                if (
                  keyLower === 'backspace' &&
                  e.currentTarget.selectionStart === 0
                ) {
                  resetItemType(index);
                  e.preventDefault();
                }
                if (keyLower === 'enter' && isEmpty) {
                  resetItemType(index);
                  e.preventDefault();
                }
                if (keyLower === 'enter' && !isEmpty) {
                  addItem(index, 'check');
                  e.preventDefault();
                }
              } else {
                if (keyLower === 'backspace') {
                  if (value.trim().length === 0) {
                    removeItem(index);
                    e.preventDefault();
                  }
                }
                if (keyLower === 'enter') {
                  const lines = value.split('\n');
                  if (lines[lines.length - 1].trim().length === 0) {
                    addItem(index, 'text');
                    e.preventDefault();
                  }
                }
              }

              // arrows
              if (keyLower === 'arrowup') {
                setFocus(index - 1);
                e.preventDefault();
              }
              if (keyLower === 'arrowleft') {
                if (e.currentTarget.selectionStart === 0) {
                  setFocus(index - 1);
                  e.preventDefault();
                }
              }
              if (keyLower === 'arrowdown') {
                setFocus(index + 1);
                e.preventDefault();
              }
              if (keyLower === 'arrowright') {
                if (e.currentTarget.selectionStart === value.length) {
                  setFocus(index + 1);
                  e.preventDefault();
                }
              }
            },
          }}
        />
        <div
          style={{
            minWidth: 24,
            height: 24,
            marginRight: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CloseLineIcon
            onClick={() => removeItem(index)}
            color={'#ccc'}
            style={{
              width: 20,
              height: 20,
              opacity: isFocused ? 1 : 0,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={{backgroundColor: 'white', width: '100vw', paddingTop: 56}}>
      <DragDropContext
        onDragEnd={(result) => {
          // dropped outside the list
          if (result.destination === undefined) {
            return;
          }
          setCheckList((prev) => ({
            id: prev.id,
            name: prev.name,
            items: reorder(
              prev.items,
              result.source.index,
              result.destination?.index,
            ),
          }));
        }}>
        <div>
          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
            }}>
            <InputBase
              value={checklist.name}
              onChange={(e) => {
                setCheckList((prev) => ({
                  ...prev,
                  name: e.currentTarget.value,
                }));
              }}
              style={{
                fontSize: 26,
                fontWeight: 700,
              }}
            />
          </div>

          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  padding: 'grid',
                  width: '100vw',
                }}>
                {checklist.items.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}>
                          {renderItem(item, index)}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <div
                  onClick={() => {
                    addItem(checklist.items.length, 'check');
                    setNextFocus(checklist.items.length);
                  }}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <div
                    style={{
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      borderRadius: 6,
                      borderStyle: 'dashed',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 16,
                    }}>
                    <AddLineIcon
                      style={{
                        color: theme.palette.primary.main,
                        opacity: 0.65,
                      }}
                    />
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default ChecklistPage;
