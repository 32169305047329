import React from 'react';
import TextField from '@mui/material/TextField/TextField';
import Box from '@mui/material/Box';
import {Button, Typography} from '@mui/material';
import ArrowGoBackLineIcon from 'remixicon-react/ArrowGoBackLineIcon';
import Routes from '../../constants/Routes';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import auth from '../../lib/firebase/auth';
import snackbar from '../../providers/snackbar';

export interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    await auth.forgotPassword(values.email);
    snackbar.success('You will receive an email');
  });

  return (
    <Box>
      <h2>Forgot password</h2>
      <Box pt={2} pb={2} display={'flex'} justifyContent={'flex-start'}>
        <Typography>
          Enter your email to receive instructions on how to reset your password
        </Typography>
      </Box>
      <TextField
        {...form.register('email')}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        type="email"
        autoComplete="email"
        autoFocus
      />

      <Button
        onClick={onSubmit}
        type="submit"
        aria-label="submit"
        variant="contained"
        color={'primary'}
        fullWidth
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          borderRadius: 40,
        }}>
        Reset password
      </Button>
      <Box
        width="100%"
        mt={2}
        mb={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="center">
        <Button
          color="primary"
          onClick={() => {
            navigate(Routes.landing);
          }}>
          <ArrowGoBackLineIcon />
          <span style={{marginLeft: '4px'}}>Back to login</span>
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotPasswordForm;
