import * as React from 'react';
import {TransitionProps} from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import {Box, Dialog, DialogProps} from '@mui/material';

export interface BottomModalProps extends DialogProps {
  children?: React.ReactNode;
}

export const BottomModal: React.FC<BottomModalProps> = ({
  children,
  ...rest
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-end', // push the dialog to bottom
        },
        '& .MuiPaper-root': {
          margin: 0,
          maxWidth: '100%',
          width: '100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
      {...rest}>
      <BottomModalHandle />
      {children}
    </Dialog>
  );
};

const Transition = React.forwardRef(
  (
    props: TransitionProps & {children: React.ReactElement},
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);
Transition.displayName = 'BaseActionPageDialogTransition';

const BottomModalHandle = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pt: 1,
        pb: 1,
      }}>
      <Box
        sx={{width: 48, height: 4, background: '#DEE2E6', borderRadius: 1}}
      />
    </Box>
  );
};
