import {createAccountForecast, ForecastItem} from './createAccountForecast';
import {Account} from '../data/accounts';

export const createPortfolioForecast = (
  accounts: Account[] | undefined,
  years: number = 30,
) => {
  if (!accounts) return [];
  const forecasts = accounts.map((e) => createAccountForecast(e, years));
  return Array.from({length: years}).map((_, j) => {
    return forecasts.reduce((result, items, index) => {
      const item = items[j];
      if (index === 0) return item;
      return addForecastItem(result, item);
    }, {} as ForecastItem);
  });
};

export const addForecastItem = (a: ForecastItem, b: ForecastItem) => {
  return {
    id: a.id,
    year: a.year,
    principal: a.principal + b.principal,
    interest: a.interest + b.interest,
    increase: a.increase + b.increase,
    deposit: a.deposit + b.deposit,
    cost: a.cost + b.cost,
    value: a.value + b.value,
  };
};
