import React from 'react';
import {Box, Button} from '@mui/material';
import FullCenterPage from '../../components/containers/FullCenterPage';
import {useNavigate} from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        background:
          'linear-gradient(45deg, rgba(148,45,196,1) 0%, rgba(8,126,225,1) 100%)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <FullCenterPage>
        <Box>
          <h2 style={{color: 'white'}}>404. Siden findes ikke</h2>
          <p style={{color: 'white'}}>
            Vi kunne desværre ikke finde den side, du ledte efter.
          </p>
          <Button style={{color: 'white'}} onClick={() => navigate(-1)}>
            Tilbage
          </Button>
        </Box>
      </FullCenterPage>
    </Box>
  );
};

export default NotFoundPage;
