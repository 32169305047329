import createLocalCrudService from '../../../../lib/firebase/createLocalCrudService';
import {useCrudListQuery} from '../../../../lib/react-query/useCrudListQuery';
import {Account} from '../data/accounts';

export const useAccounts = (id: string | undefined = '0') => {
  const service = createLocalCrudService<Account>(
    `accounts/${id}`,
    [],
    (a, b) => a.index?.localeCompare(b.index ?? '') ?? 0,
  );
  return useCrudListQuery(service);
};
