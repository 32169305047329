import {
  Box,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import {BaseDialog, BaseDialogProps} from '../components/BaseDialog/BaseDialog';
import {BaseDialogActions} from '../components/BaseDialog/BaseDialogActions';
import {BottomModal} from '../components/BottomModal/BottomModal';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import {RemixiconReactIconProps} from 'remixicon-react';

export type AlertDialogVariant = 'info' | 'warning' | 'error' | 'success';

export interface AlertDialogProps extends BaseDialogProps {
  variant?: AlertDialogVariant;
  title?: string;
  subtitle?: string;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  variant,
  title,
  subtitle,
  maxWidth = 'sm',
  ...rest
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <BottomModal open={rest.open} onClose={rest.onClose}>
        <Box
          sx={{
            minHeight: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            gap: 2,
          }}>
          <AlertDialogIcon variant={variant} size={40} />
          <Box sx={{textAlign: 'center'}}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
          </Box>
        </Box>
        <BaseDialogActions isMobile={true} actions={rest.actions || []} />
      </BottomModal>
    );
  }

  return (
    <BaseDialog maxWidth={maxWidth} {...rest}>
      <DialogContent>
        <Stack spacing={2} alignItems={'center'}>
          <AlertDialogIcon variant={variant} size={40} />
          <Typography variant={'h4'}>{title}</Typography>
          <Typography variant={'body1'} whiteSpace={'break-spaces'}>
            {subtitle}
          </Typography>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export interface AlertDialogIconProps {
  variant?: AlertDialogVariant;
  size?: RemixiconReactIconProps['size'];
}

export const AlertDialogIcon: React.FC<AlertDialogIconProps> = ({
  variant = 'info',
  size,
}) => {
  const theme = useTheme();

  if (variant === 'success') {
    return <CheckLineIcon color={theme.palette.success.main} size={size} />;
  }
  if (variant === 'warning') {
    return (
      <ErrorWarningLineIcon color={theme.palette.warning.main} size={size} />
    );
  }
  if (variant === 'error') {
    return (
      <ErrorWarningLineIcon color={theme.palette.error.main} size={size} />
    );
  }
  return <InformationLineIcon color={theme.palette.info.main} size={size} />;
};
